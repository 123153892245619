import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Button, Card, Slider, Switch, Typography } from "@mui/material";

import { ArrowForwardIos } from "@mui/icons-material";

import NotebookIcon from "../../../fragments/NotebookIcon";

export default function SubscriptionsCard({
  rate,
  setRate,
  limited,
  setLimited,
  pocketbook,
  setPocketbook,
}) {
  const navigate = useNavigate();

  const handleTogglePocketbook = () => {
    if (pocketbook === "on") {
      setPocketbook("off");
    } else {
      setPocketbook("on");
    }
  };

  return (
    <Card sx={{ p: 2, my: 4, borderRadius: "20px" }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" color="info">
          Subscriptions
        </Typography>
        <Button
          variant="text"
          color="info"
          sx={{ my: 1 }}
          // onClick={() => (window.location.href = "/data")}
          onClick={() => navigate("/data")}
          endIcon={<ArrowForwardIos style={{ fontSize: "1.0rem" }} />}
        >
          See Data
        </Button>
      </Box>
      <Box className="montly-rate-slider" sx={{ my: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              // my: 2,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* <NotebookIcon /> */}
            <Typography variant="body1" color="secondary">
              Monthly
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Slider
            value={rate}
            onChange={(e, newValue) => setRate(newValue)}
            min={2}
            max={20}
            step={1}
            valueLabelDisplay="auto"
            color="secondary"
          />
          <Box sx={{ width: "150px", ml: 4 }}>
            <Typography>${rate} / month</Typography>
          </Box>
        </Box>
      </Box>

      <Box className="pocketbook-rate-slider" sx={{ my: 2 }}>
        <Box
          style={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* <NotebookIcon color="lightgray" /> */}
            <Typography variant="body1" color="lightgray">
              Pocketbook Edition
            </Typography>
          </Box>

          <Switch
            checked={pocketbook === "on"}
            onChange={handleTogglePocketbook}
            color="info"
          />
        </Box>

        {/* Conditionally render the slider based on the switch */}
        {pocketbook === "on" && (
          <>
            <Typography variant="caption" color="darkgray">
              "Everything but music" for users outside of US
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{ mb: 4, mt: 2 }}
            >
              <Slider
                value={limited}
                onChange={(e, newValue) => setLimited(newValue)}
                min={2}
                max={20}
                step={1}
                valueLabelDisplay="auto"
                color="info"
              />
              <Box sx={{ width: "150px", ml: 4 }}>
                <Typography>${limited} / month</Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
}
