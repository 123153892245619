import { useEffect, useState } from "react";
import SimpleAppBar from "../fragments/SimpleAppBar";
import Box from "@mui/material/Box";

import PrivacyPolicy from "../fragments/PrivacyPolicy";

export default function Privacy() {
  const [privacyHtml, setPrivacyHtml] = useState("");

  useEffect(() => {
    fetch("/privacy.html")
      .then((response) => response.text())
      .then((data) => {
        setPrivacyHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching privacy policy:", error);
      });
  }, []);

  return (
    <Box>
      <SimpleAppBar />
      <Box sx={{ p: 2 }}>
        <PrivacyPolicy />
      </Box>
    </Box>
  );
}
