import axios from "axios";
import { React, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";

import {
  ChatBubbleOutline,
  Star,
  StarBorder,
  Verified,
} from "@mui/icons-material";

import ActionDrawer from "@/components/ActionDrawer";
import ImageCarousel from "@/components/ImageCarousel";
import ActiveContentDrawer from "@/components/ActiveContentDrawer";

import { API_BASE, currentUser } from "@/App";

import { fromNow } from "@/utils";

export default function FeedCard({ content }) {
  const navigate = useNavigate();

  const [favorite, setFavorite] = useState(content.user_favorite);
  const [favoriteCount, setFavoriteCount] = useState(content.favorite_count);
  const [open, setOpen] = useState(false);

  const handleFavorite = async () => {
    setFavorite(!favorite);
    setFavoriteCount(favorite ? favoriteCount - 1 : favoriteCount + 1);
    const endpoint = API_BASE + "/event";
    const response = await axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      type: favorite ? "unfavorite" : "favorite",
    });
  };

  return (
    <Box id={content.key} sx={{ my: 2 }}>
      <Box id="post-content-container" style={{ flex: 1 }}>
        <Box
          id="post-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            id="user-detail"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            sx={{ p: 1 }}
            onClick={() => navigate("/" + content.owner.key)}
          >
            <Avatar
              src={content.owner.meta.profile}
              sx={{ width: 30, height: 30, marginRight: 1 }}
              onClick={() => navigate("/" + content.owner.key)}
            />
            <Typography
              variant="body"
              style={{ marginRight: "5px", fontWeight: "bold" }}
            >
              {content.owner.name}
            </Typography>

            <Verified
              color={content.owner.verified ? "secondary" : "disabled"}
              style={{ fontSize: "15px", marginRight: "5px" }}
            />
          </Box>

          <ActionDrawer content={content} />
        </Box>

        <MediaContentCard content={content} setOpen={setOpen} />

        <Box id="post-footer" sx={{ p: 2 }}>
          <Box
            id="post-actions"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // marginTop: "10px",
            }}
          >
            <Box id="post-buttons">
              <IconButton onClick={handleFavorite} sx={{ pl: 0 }}>
                {favorite ? (
                  <Star color="secondary" />
                ) : (
                  <StarBorder style={{ color: "" }} />
                )}
              </IconButton>
              <Typography variant="caption" style={{ marginRight: "5px" }}>
                {favoriteCount > 0 && favoriteCount}
              </Typography>

              <IconButton
                onClick={() => setOpen(true)}
                style={{ marginLeft: "5px" }}
              >
                <ChatBubbleOutline style={{ color: "" }} />
              </IconButton>
              <Typography variant="caption" style={{ marginRight: "5px" }}>
                {content.comment_count + 1}
              </Typography>
            </Box>
          </Box>
          <Box style={{ wordWrap: "break-all", whiteSpace: "pre-wrap" }}>
            <Typography variant="body" style={{ marginRight: "5px" }}>
              <span style={{ fontWeight: 600 }}>{content.owner.key} </span>
              {content.meta.text}
            </Typography>
            {content.meta.tags && (
              <Typography variant="caption" style={{ color: "darkgray" }}>
                {content.meta.tags.join(", ")}
              </Typography>
            )}
          </Box>

          <Typography
            variant="caption"
            style={{ marginRight: "5px", color: "darkgray" }}
          >
            {fromNow(content.created)}
          </Typography>
        </Box>
        <Divider sx={{ mt: 2 }} />
      </Box>

      <ActiveContentDrawer content={content} open={open} setOpen={setOpen} />
    </Box>
  );
}

export function MediaContentCard({ content, setOpen }) {
  const contentType = content?.type || "";
  const contentURL = content?.meta?.url || "";

  const handleClick = (content) => {
    if (["image"].includes(contentType)) {
      setOpen(true);
    }
  };

  return (
    <Box sx={{ mt: 1 }} onClick={() => handleClick(content)}>
      {["video"].includes(contentType) && (
        <Card style={{ borderRadius: "0px" }}>
          <ReactPlayer
            url={contentURL}
            light={content.meta.cover}
            playing
            controls
            width="100%"
            height="400px"
          />
        </Card>
      )}
      {["image"].includes(contentType) && (
        <Card style={{ borderRadius: "0px" }}>
          <CardMedia component="img" src={contentURL} />
        </Card>
      )}
      {["carousel"].includes(contentType) && (
        <Box style={{ borderRadius: "0px" }}>
          <ImageCarousel content={content} height={400}/>
        </Box>
      )}
    </Box>
  );
}
