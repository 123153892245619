import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

import FeedCard from "../Threads/FeedCard";
import { Divider } from "@mui/material";

export default function ThreadsContainer({ artistContent }) {
  const navigate = useNavigate();

  return (
    <>
    {artistContent?.length > 0 && (
      <Box
        id="threads"
        sx={{
          background:
            "linear-gradient(180deg, rgba(18,18,18,0) 50%, rgba(36,36,36,1) 200%)",
          pb: 8,
          pt: 2,
          // px: 2,
        }}
      >
        <Box
          style={{ display: "flex", justifyContent: "space-between" }}
          sx={{ mt: 4, px: 2 }}
        >
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            Forums
          </Typography>
          <IconButton onClick={() => navigate("/threads")}>
            <ArrowForwardIos />
          </IconButton>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Box>
            {artistContent?.sort((a, b) => b.updated - a.updated)
              .slice(0, 3)
              .map((thread, index) => (
                <Box key={index}>
                  <FeedCard key={index} content={thread} />
                  <Divider />
                </Box>
              ))}
          </Box>

          <Box
            sx={{ mt: 4 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => navigate("/threads")}
          >
            <Typography variant="body1" color="darkgray">
              {`View all topics or start a new one`}
            </Typography>
            <ArrowForwardIos
              style={{ fontSize: "15px", color: "darkgray" }}
              sx={{ ml: 2 }}
            />
          </Box>
        </Box>
      </Box>
    )}
    </>
  );
}
