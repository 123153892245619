import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Toolbar,
} from "@mui/material";

import FeedCard from "./FeedCard";

import { API_BASE, currentUser, artistKey } from "../../App";
import ArtistLogo from "../../fragments/ArtistLogo";
import { ArrowBackIos } from "@mui/icons-material";



export default function LoadingCard() {
  return (
    <Box sx={{ mb: 10, width: '100%'  }}>
      <Box
        sx={{
          mb: 1,
          p: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton
          variant="rectangular"
          width="45%"
          height={10}
          style={{ borderRadius: "5px" }}
        />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={430} />
      <Skeleton
        variant="rectangular"
        width="80%"
        height={10}
        sx={{ mx: 1, my: 2 }}
        style={{ borderRadius: "5px" }}
      />
      <Skeleton
        variant="rectangular"
        width="45%"
        height={10}
        sx={{ m: 1 }}
        style={{ borderRadius: "5px" }}
      />
    </Box>
  );
}
