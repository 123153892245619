import {
  AppBar,
  Box,
  CardMedia,
  Container,
  Drawer,
  IconButton,
  Slider,
  Toolbar,
  Typography,
} from "@mui/material";
import { useContext } from "react";

import {
  ExpandMore,
  PauseCircle,
  PlayCircle,
  Repeat,
  Shuffle,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";

import ActionDrawer from "@/components/ActionDrawer";
import CommentsContainer from "@/components/Comments/CommentsContainer";
import { MusicContext } from "@/contexts/MusicContext";
import CaptionContainer from "@/fragments/CaptionContainer";
import { formatSeconds, getContentArtwork } from "@/utils";

export default function FullSizePlayer({ compact, setCompact }) {
  const { currentSong } = useContext(MusicContext);

  return (
    <Drawer
      anchor="bottom"
      open={!compact}
      onClose={() => setCompact(!compact)}
      PaperProps={{ style: { background: "#47596E" } }}
    >
      <AppBar position="sticky" elevation={0} style={{ background: "#47596E" }}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={() => setCompact(!compact)}>
            <ExpandMore style={{ fontSize: "30px" }} />
          </IconButton>

          {/* <Typography variant="h6" color="inherit">
            {currentSong?.meta["title"]}
          </Typography> */}

          <ActionDrawer content={currentSong} />
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              borderRadius: "10px",
              height: "400px",
              my: 1,
            }}
            src={getContentArtwork(currentSong)}
          />

          {/* Assuming PlayerControls is imported elsewhere */}
          <PlayerControls />
        </Box>

        <LyricsContainer currentSong={currentSong} />
        <Box sx={{ mt: 2, pt: 2, mb: 10 }}>
          <CaptionContainer content={currentSong} />
          <CommentsContainer content={currentSong} />
        </Box>
      </Container>
    </Drawer>
  );
}

function LyricsContainer({ currentSong }) {
  // Split the lyrics into an array of lines
  const lines = currentSong.meta.lyrics?.split("\n").map((line, index) => (
    // Render each line in a Typography component, wrapped in a div for proper spacing
    <div key={index}>
      <Typography>{line}</Typography>
    </div>
  ));

  return (
    <>
      {currentSong.meta.lyrics && currentSong.meta.lyrics != "" && (
        <Box
          id="lyrics-container"
          style={{
            background: "#02B2AF",
            maxHeight: "50vh",
            overflow: "auto",
            borderRadius: "10px",
          }}
          sx={{ p: 2, my: 2 }}
        >
          <Typography
            variant="body1"
            color="inherit"
            sx={{ fontWeight: 600, mb: 2 }}
          >
            Lyrics
          </Typography>
          {lines} {/* Render the array of lines here */}
        </Box>
      )}
    </>
  );
}

function PlayerControls({}) {
  const {
    currentSong,
    isPlaying,
    handlePlay,
    handlePause,
    handleNext,
    handlePrevious,
    duration,
    currentTime,
    handleTimeSliderChange,
    repeatMode,
    toggleRepeatMode,
    shuffleMode,
    toggleShuffleMode,
  } = useContext(MusicContext);

  const filteredTags = currentSong.meta.tags
    ? currentSong.meta.tags.filter((tag) => tag.trim() !== "")
    : [];

  return (
    <Box id="player-controls" sx={{ width: "100%", my: 2 }}>
      <Typography variant="h6" color="inherit">
        {currentSong?.meta["title"]}
      </Typography>
      <Typography
        style={{ marginTop: "0px", fontSize: "0.8em", color: "lightgray" }}
      >
        {currentSong?.owner.name}
        {filteredTags.length > 0 && `, ${filteredTags.join(", ")}`}
      </Typography>

      <Slider
        style={{ marginTop: "10px", paddingBottom: "0px" }}
        value={currentTime}
        max={duration}
        onChange={handleTimeSliderChange}
        color="secondary"
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-10px",
          marginBottom: "0",
        }}
      >
        <Typography variant="caption" color="lightgray">
          {formatSeconds(currentTime)}{" "}
        </Typography>
        <Typography variant="caption" color="lightgray">
          {formatSeconds(duration - currentTime)}
        </Typography>
      </Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={toggleShuffleMode}>
          {shuffleMode ? (
            <Shuffle style={{ fontSize: "30px" }} color="secondary" />
          ) : (
            <Shuffle style={{ fontSize: "30px", opacity: 0.7 }} />
          )}
        </IconButton>
        <IconButton onClick={handlePrevious}>
          <SkipPrevious style={{ fontSize: "50px" }} />
        </IconButton>

        {isPlaying ? (
          <IconButton onClick={handlePause}>
            <PauseCircle style={{ fontSize: "70px" }} />
          </IconButton>
        ) : (
          <IconButton onClick={() => handlePlay(currentSong)}>
            <PlayCircle style={{ fontSize: "70px" }} />
          </IconButton>
        )}

        <IconButton onClick={handleNext}>
          <SkipNext style={{ fontSize: "50px" }} />
        </IconButton>
        <IconButton onClick={toggleRepeatMode}>
          {repeatMode ? (
            <Repeat style={{ fontSize: "30px" }} color="secondary" />
          ) : (
            <Repeat style={{ fontSize: "30px", opacity: 0.7 }} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}
