import React from "react";

import AppLogo from "../assets/app-logo.svg";

export default function AppLogoContainer() {
  return (
    <img
      src={AppLogo}
      alt="Notebook"
      style={{ width: 200 }}
    />
  );
}
