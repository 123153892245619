import { React } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import AppLogo from "../fragments/AppLogo";

export default function PageAppBar() {
  const navigate = useNavigate();

  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
        <IconButton onClick={() => navigate(-1)} >
          <ArrowBackIosIcon />
        </IconButton>
        </Box>
        
        <AppLogo />
        <Box style={{ width: "40px" }} />
      </Toolbar>
    </AppBar>
  );
}

