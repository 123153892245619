import axios from "axios";
import React, { useState } from "react";

import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";

import VerifiedIcon from "@mui/icons-material/Verified";

import LearnMoreDrawer from "./LearnMoreDrawer";

import { API_BASE } from "../../App";
import { setArtistKey, setCurrentUser, setToken } from "../../utils";

export default function NewArtistContainer({
  countryCode,
  phone,
  loading,
  setLoading,
  accessKey,
}) {
  // const [name, setName] = useState("");
  // const [handle, setHandle] = useState("");
  const [username, setUsername] = useState("");
  const [learnMore, setLearnMore] = useState(false);

  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const formattedPhone = () => {
    return countryCode + phone;
  };

  const handleCreateArtist = async () => {
    setLoading(true);

    const payload = {
      phone: formattedPhone(),
      username: username,
      access_key: accessKey,
      terms: agreeToTerms,
    };

    const response = await axios.post(API_BASE + "/artist/register", payload);
    setCurrentUser(response.data.user);
    setArtistKey(response.data.user.key);
    setToken(response.data.token);
    localStorage.setItem("hideAlert", "false"); // Store as a string
    // await new Promise((resolve) => setTimeout(resolve, 500));
    window.location.href = "/";
  };

  const handleSetUsername = (e) => {
    // all lowercase no spaces
    setUsername(e.target.value.replace(/\s/g, "").toLowerCase());
  };

  return (
    <Box>
      <Box sx={{ my: 2, width: 350 }}>
        <TextField
          label="Username"
          variant="outlined"
          helperText="Once published, available at notebook.fm/{username}"
          required
          value={username}
          onChange={handleSetUsername}
          fullWidth
          autoComplete="off"
          sx={{ my: 2 }}
          InputProps={{
            style: {
              borderRadius: "30px",
            },
          }}
        />

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
          sx={{ my: 4 }}
        >
          <Checkbox
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
            sx={{p: 0, mr: 2, mt: 0.5}}
          />
          <Typography variant="body1" color="darkgray">
            I understand that my notebook will stay in Private Mode (only
            accessible to me) until I sign the Artist Agreement and hit Publish.
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
              onClick={() => setLearnMore(true)}
            >
              Learn More
            </span>
            .
          </Typography>
        </Box>

        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleCreateArtist}
          disabled={username.length < 3 || loading || !agreeToTerms}
          startIcon={<VerifiedIcon />}
          style={{ borderRadius: "20px" }}
          sx={{ my: 2 }}
        >
          Get Started
        </Button>
      </Box>
      <LearnMoreDrawer open={learnMore} onClose={() => setLearnMore(false)} />
    </Box>
  );
}
