import React, { useState } from "react";
import { Box, IconButton, CardMedia, useMediaQuery } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";

export default function ImageCarousel({ content, height, borderRadius }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = content.meta.urls;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Set up swipe handlers using react-swipeable
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Allow swipe with mouse as well
  });

  // Use useMediaQuery to detect if the screen size is desktop or larger
  // const isDesktop = useMediaQuery("(min-width:768px)");
  const isDesktop = true;

  return (
    <>
      <Box
        {...handlers} // Apply swipe handlers to the Box component
        sx={{
          position: "relative",
          width: "100%",
          height: height,
          overflow: "hidden",
        }}
      >
        {/* Display the current image */}
        <CardMedia
          component="img"
          image={images[currentIndex]}
          alt={`image-${currentIndex}`}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: borderRadius,
          }}
        />

        {/* Left navigation button - only show on desktop */}
        {isDesktop && (
          <IconButton
            onClick={handlePrev}
            size="small"
            sx={{
              position: "absolute",
              top: "50%",
              left: "10px",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
            }}
          >
            <ArrowBackIos
              style={{
                marginLeft: "4px",
                marginRight: "-4px",
                fontSize: "1.3rem",
              }}
            />
          </IconButton>
        )}

        {/* Right navigation button - only show on desktop */}
        {isDesktop && (
          <IconButton
            onClick={handleNext}
            size="small"
            sx={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
            }}
          >
            <ArrowForwardIos sx={{fontSize: "1.3rem"}}/>
          </IconButton>
        )}
      </Box>
      {/* Dots indicator below the carousel */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          // position: "absolute",
          // bottom: "20px",
          width: "100%",
        }}
      >
        {images.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor: currentIndex === index ? "#fff" : "gray",
              margin: "0 4px",
            }}
          />
        ))}
      </Box>
    </>
  );
}
