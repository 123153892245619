import React from "react";

export default function NotebookIcon({ color = "#04FFFF", opacity = 1, size = 18 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 161 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: opacity }}
    >
      <path
        d="M10.9826 158.523C7.37982 158.523 4.6487 157.651 2.78922 155.908C0.929741 154.049 0 151.666 0 148.761C0 145.623 0.871632 142.717 2.6149 140.044C4.47438 137.255 6.85684 135.047 9.76228 133.42C12.6677 131.793 15.6894 130.979 18.8272 130.979C20.2219 130.979 21.6165 131.154 23.0111 131.502C24.5219 131.735 25.9746 132.142 27.3692 132.723V48H33.8193V133.246C33.8193 138.243 32.7152 142.659 30.5071 146.494C28.4152 150.213 25.626 153.177 22.1394 155.385C18.7691 157.477 15.0502 158.523 10.9826 158.523Z"
        fill={color}
      />
      <path
        d="M79.299 111.253C75.6962 111.253 72.9651 110.382 71.1056 108.638C69.2461 106.779 68.3164 104.397 68.3164 101.491C68.3164 98.3532 69.188 95.4478 70.9313 92.7748C72.7908 89.9856 75.1732 87.7774 78.0787 86.1504C80.9841 84.5234 84.0058 83.7098 87.1437 83.7098C88.5383 83.7098 89.9329 83.8842 91.3275 84.2328C92.8383 84.4652 94.291 84.872 95.6856 85.4531V0.730469H102.136V85.9761C102.136 90.9734 101.032 95.3897 98.8235 99.2249C96.7316 102.944 93.9424 105.907 90.4559 108.116C87.0855 110.207 83.3666 111.253 79.299 111.253Z"
        fill={color}
      />
      <path
        d="M137.518 158.523C133.915 158.523 131.184 157.651 129.324 155.908C127.465 154.049 126.535 151.666 126.535 148.761C126.535 145.623 127.407 142.717 129.15 140.044C131.01 137.255 133.392 135.047 136.297 133.42C139.203 131.793 142.225 130.979 145.362 130.979C146.757 130.979 148.152 131.154 149.546 131.502C151.057 131.735 152.51 132.142 153.904 132.723V48H160.354V133.246C160.354 138.243 159.25 142.659 157.042 146.494C154.95 150.213 152.161 153.177 148.675 155.385C145.304 157.477 141.585 158.523 137.518 158.523Z"
        fill={color}
      />
    </svg>
  );
}

 