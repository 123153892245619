import axios from "axios";

import { ExpandMore, ShoppingCart } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Container,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import ActionDrawer from "@/components/ActionDrawer";
import CommentsContainer from "@/components/Comments/CommentsContainer";
import CaptionContainer from "@/fragments/CaptionContainer";
import PollContainer from "@/components/PollContainer";

import { API_BASE, currentUser } from "@/App";

export default function ActiveContentDrawer({ content, open, setOpen }) {
  return (
    <>
      {content && (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            style: { background: "#47596E", borderRadius: "20px 20px 0 0" },
          }}
        >
          <AppBar
            position="sticky"
            elevation={0}
            style={{
              background: "#47596E",
            }}
          >
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <IconButton
                onClick={() => setOpen(false)}
                style={{ margin: "0 0 0 -10px" }}
                color="inherit"
              >
                <ExpandMore style={{ fontSize: "30px" }} />
              </IconButton>

              <ActionDrawer content={content} />
            </Toolbar>
            <Box>
              <Container
                maxWidth="sm"
                sx={{ pt: 1, pb: 4, borderBottom: "1px solid #2E3A4D" }}
              >
                {content.type === "thread" ? (
                  <Typography
                    textAlign="center"
                    sx={{ fontWeight: 800, fontSize: "1.2rem" }}
                  >
                    {content.meta.title}
                  </Typography>
                ) : (
                  <CaptionContainer content={content} />
                )}
              </Container>
            </Box>
          </AppBar>

          <Box sx={{ height: "60vh", px: 1 }}>
            <Container maxWidth="sm" sx={{ pb: 10 }}>
              {content.type === "thread" && (
                <Box sx={{ mt: 2 }}>
                  <CaptionContainer content={content} />
                </Box>
              )}

              {content.type == "merch" && <MerchContainer content={content} />}
              {content.type === "poll" && (
                <Box sx={{ mb: 4 }}>
                  <PollContainer content={content} />
                </Box>
              )}
              <CommentsContainer content={content} />
            </Container>
          </Box>
        </Drawer>
      )}
    </>
  );
}

function MerchContainer({ content }) {
  const handleBuyNow = async (content) => {
    const result = confirm(
      "You will now be redirected to the artist's store. Continue?"
    );

    // log event "click_buy_now"
    const endpoint = API_BASE + "/event";
    const response = axios.post(endpoint, {
      user_key: currentUser.key,
      content_key: content.key,
      type: "click_buy_now",
    });

    window.location.href = content.meta.link;
  };

  return (
    <Box style={{ borderRadius: "20px" }} sx={{ mt: 2 }}>
      <CardMedia
        component="img"
        src={content.meta["cover"]}
        style={{ borderRadius: "10px" }}
      />

      <Typography sx={{ mt: 1 }}>${content.meta.price}</Typography>
      <Button
        fullWidth
        startIcon={<ShoppingCart />}
        variant="outlined"
        color="secondary"
        sx={{ my: 2 }}
        onClick={() => handleBuyNow(content)}
        style={{ borderRadius: "20px" }}
      >
        BUY
      </Button>
    </Box>
  );
}
