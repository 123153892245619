import axios from "axios";
import { React, useEffect, useState } from "react";

import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import FeedCard from "./FeedCard";
import NewThreadContainer from "./NewThreadContainer";

import { API_BASE, artistKey, currentUser } from "../../App";

import BottomTabs from "../../components/BottomTabs";
import MainAppBar from "../../components/MainAppBar";

export default function Threads() {
  const params = new URLSearchParams(location.search);
  const contentKey = params.get("contentKey");

  const [loading, setLoading] = useState(true);
  const [artistContent, setArtistContent] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortMethod, setSortMethod] = useState("recent"); // State for sorting method

  const fetchArtistContent = async () => {
    const endpoint = API_BASE + "/threads";
    const response = await axios.get(endpoint, {
      params: {
        user_key: currentUser.key,
        artist_key: artistKey,
      },
    });
    setArtistContent(response.data.content);
    setLoading(false);
  };

  useEffect(() => {
    fetchArtistContent();
  }, []);

  useEffect(() => {
    if (!loading && contentKey) {
      const element = document.getElementById(contentKey);
      console.log("scrolling to", contentKey);
      if (element) {
        element.scrollIntoView();
        setTimeout(() => {
          window.scrollBy(0, -50); // Scrolls up by 50px
        }, 1);
      }
    }
  }, [loading, contentKey]);

  // Sort the artistContent based on the selected sort method
  const sortedContent = artistContent?.sort((a, b) => {
    if (sortMethod === "recent") {
      return b.updated - a.updated;
    } else if (sortMethod === "comment_count") {
      return b.comment_count - a.comment_count;
    }
    return 0;
  });

  return (
    <Box
      sx={{
        minHeight: "80vh",
        background:
          "linear-gradient(180deg, rgba(18,18,18,0) 0%, rgba(36,36,36,1) 200%)",
        pb: 15,
      }}
    >
      <MainAppBar />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
            mt: 2,
            p: 2,
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={36}
            style={{ borderRadius: "25px" }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            style={{ borderRadius: "25px" }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            style={{ borderRadius: "25px" }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            style={{ borderRadius: "25px" }}
          />
        </Box>
      ) : (
        <Box id="main-feed">
          <Box sx={{ px: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              fullWidth
              size="large"
              style={{ borderRadius: "30px" }}
              sx={{ my: 2 }}
              onClick={() => setOpen(true)}
            >
              New Topic
            </Button>

            <FormControl
              fullWidth
              variant="outlined"
              sx={{ my: 2, opacity: 0.9 }}
            >
              <InputLabel>Sort By</InputLabel>
              <Select
                value={sortMethod}
                onChange={(e) => setSortMethod(e.target.value)}
                label="Sort By"
                sx={{ borderRadius: "30px" }}
              >
                <MenuItem value="recent">Most Recent</MenuItem>
                <MenuItem value="comment_count">Most Active</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box style={{ marginBottom: "100px" }}>
            {sortedContent?.map((content, index) => (
              <Box key={index}>
                <FeedCard key={index} content={content} />
                <Divider />
              </Box>
            ))}
          </Box>
        </Box>
      )}

      <NewThreadContainer
        open={open}
        setOpen={setOpen}
        fetchArtistContent={fetchArtistContent}
      />
      <BottomTabs />
    </Box>
  );
}
