import React from "react";

import { Box, Typography } from "@mui/material";

import NotebookIcon from "@/fragments/NotebookIcon";

import { artistKey } from "@/App";

export default function ArtistLogo({ }) {
  return (
    <Box
      className="artist-logo"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      
      <NotebookIcon size={24}/>

      <Typography
        style={{
          fontFamily: "'Montserrat Alternates', sans-serif",
          fontWeight: 700,
          fontSize: "1.4rem",
          marginBottom: '0px',
          marginLeft: '12px'
        }}
        // sx={{ ml: 2}}
      >
        {artistKey || "notebook"}
        
      </Typography>
    </Box>
  );
}
