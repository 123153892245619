import axios from "axios";

import ReactPlayer from "react-player";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia
} from "@mui/material";

import {
  Edit
} from "@mui/icons-material";


import AppSVG from "../../../assets/app-icon.svg";

import CoverDefault from "../../../assets/cover-default.jpg";

import { API_BASE, authHeaders, currentUser } from "../../../App";

export function ProfileImageContainer({ profile, setProfile, setUploading }) {
  const handleUpdateFile = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("img", document.getElementById("profile").files[0]);
    formData.append("user_key", currentUser.key);

    const response = await fetch(`${API_BASE}/user/upload-file`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    setProfile(data.url);
    setUploading(false);
  };

  return (
    <Box
      id="edit-profile"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      
    >
      <Avatar src={profile} sx={{ width: 75, height: 75 }} />

      <label htmlFor="profile">
        <Button
          variant="text"
          color="primary"
          component="span"
          startIcon={<Edit />}
          
        >
          Edit
        </Button>
      </label>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="profile"
        type="file"
        onChange={() => handleUpdateFile("profile")}
      />
    </Box>
  );
}


export function WelcomeVideoContainer({ welcome, setWelcome, setUploading }) {
  const handleUploadWelcomeVideo = async (file) => {
    if (!file) return; // Ensure file is selected

    try {
      const endpoint = API_BASE + "/signed-upload";
      const response = await axios.get(endpoint, authHeaders);
      const { upload_url: uploadURL, public_url: publicURL } = response.data;

      setUploading(true);
      await fetch(uploadURL, {
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
      });
      setWelcome(publicURL);
    } catch (error) {
      console.error("Error uploading welcome video:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setUploading(false);
    }

  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Card style={{ borderRadius: "20px" }}>
        <ReactPlayer url={welcome} controls height={"140px"} width={"140px"} />
      </Card>

      <label htmlFor="welcome">
        <Button
          variant="text"
          color="primary"
          component="span"
          startIcon={<Edit />}
          sx={{ my: 1 }}
        >
          Welcome
        </Button>
      </label>
      <input
        accept="video/*"
        style={{ display: "none" }}
        id="welcome"
        type="file"
        onChange={(e) => handleUploadWelcomeVideo(e.target.files[0])}
      />
    </Box>
  );
}

export function MusicCoverContainer({ artwork, setArtwork, setUploading }) {
  const handleUpdateFile = async () => {
    const formData = new FormData();
    formData.append("img", document.getElementById("artwork").files[0]);
    formData.append("user_key", currentUser.key);

    setUploading(true);
    const response = await fetch(`${API_BASE}/user/upload-file`, {
      method: "POST",
      body: formData,
    });
    setUploading(false);

    const data = await response.json();
    setArtwork(data.url);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CardMedia
        component="img"
        src={artwork || AppSVG}
        style={{ width: "140px", height: "140px", borderRadius: "10px" }}
      />
      <Box>
        <label htmlFor="artwork">
          <Button
            variant="text"
            color="primary"
            component="span"
            startIcon={<Edit />}
            sx={{ my: 1 }}
          >
            Music
          </Button>
        </label>
      </Box>

      <input
        accept="image/*"
        style={{ display: "none" }}
        id="artwork"
        type="file"
        onChange={handleUpdateFile}
      />
    </Box>
  );
}

export function NotebookCoverContainer({ cover, setCover, setUploading }) {
  const handleUpdateFile = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("img", document.getElementById("cover").files[0]);
    formData.append("user_key", currentUser.key);
    const response = await fetch(`${API_BASE}/user/upload-file`, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    setCover(data.url);
    setUploading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CardMedia
        component="img"
        src={cover || CoverDefault}
        style={{ width: "140px", height: "140px", borderRadius: "10px" }}
      />
      <Box>
        <label htmlFor="cover">
          <Button
            variant="text"
            color="primary"
            component="span"
            startIcon={<Edit />}
            sx={{ my: 1 }}
          >
            Cover
          </Button>
        </label>
      </Box>

      <input
        accept="image/*"
        style={{ display: "none" }}
        id="cover"
        type="file"
        onChange={handleUpdateFile}
      />
    </Box>
  );
}

