import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { API_BASE, currentUser } from "../../App.js";

export default function GenderContainer() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    setLoading(true);
    try {
      const endpoint = API_BASE + "/data/gender";
      const response = await axios.get(endpoint, {
        params: { artist_key: currentUser.key },
      });
      const sortedResults = response.data.results.sort((a, b) => b.count - a.count);
      setResults(sortedResults);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching gender data:", error);
      setLoading(false);
    }
  };

  const calculatePercentage = (count, total) => {
    return total === 0 ? 0 : ((count / total) * 100).toFixed(2);
  };

  const totalSubscribers = results.reduce((sum, item) => sum + item.count, 0);

  return (
    <Card style={{ borderRadius: "10px"}} sx={{p: 2, my: 2}}>
      <CardContent>
        
        {loading ? (
          <Box>
            <Skeleton variant="text" width={120} sx={{ mt: 1 }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" width={220} />
            <Skeleton variant="text" width={120} sx={{ mt: 1 }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" width={220} />
            <Skeleton variant="text" width={120} sx={{ mt: 1 }} />
            <Skeleton variant="text" />
            <Skeleton variant="text" width={220} />
          </Box>
        ) : (
          results.map((item, index) => {
            const percentage = calculatePercentage(item.count, totalSubscribers);
            return (
              <Box key={index} sx={{ my: 2 }}>
                <Typography variant="body2">{item.gender}</Typography>
                <LinearProgress
                  variant="determinate"
                  value={parseFloat(percentage)}
                  sx={{ height: 10, borderRadius: 5, my: 0.5 }}
                />
                <Typography variant="body2" color="textSecondary">
                  {item.count} ({parseFloat(percentage).toFixed(1)}%)
                </Typography>
              </Box>
            );
          })
        )}
        {!loading && totalSubscribers === 0 && (
          <Typography variant="body1">No subscribers yet.</Typography>
        )}
      </CardContent>
    </Card>
  );
}