import React, { useEffect, useState } from "react";

import { Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  AutoStories,
  ForumOutlined,
  Home,
  Redeem
} from "@mui/icons-material";

import ArtistFab from "./ArtistFab";
import NotebookIcon from "../fragments/NotebookIcon";



export default function BottomTabs() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (e, newValue) => {
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/notes");
        break;
      case 2:
        navigate("/music");
        break;
      case 3:
        navigate("/threads");
        break;
      case 4:
        navigate("/redeem");
        break;
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setActiveTab(0);
    } else if (window.location.pathname === "/notes") {
      setActiveTab(1);
    } else if (window.location.pathname === "/music") {
      setActiveTab(2);
    } else if (window.location.pathname === "/threads") {
      setActiveTab(3);
    } else if (window.location.pathname === "/redeem") {
      setActiveTab(4);
    }
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
    <Tabs
      value={activeTab}
      onChange={handleTabChange} // Updated to use the new handler
      TabIndicatorProps={{ sx: { top: 0 } }}
      textColor="inherit"
      variant="fullWidth"
      sx={{
        position: "fixed",
        bottom: 0,
        bgcolor: "rgba(18, 18, 18, 0.95)",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
        pt: 0,
        pb: 2,
        "& .MuiTab-root": {
          minWidth: "70px", // Override the min-width here
          height: "68px",
        },
      }}
    >
      <Tab icon={<Home />} sx={{ pt: 0 }} />
      <Tab icon={<AutoStories />} sx={{ pt: 0 }} />
      <Tab icon={<NotebookIcon size={24} />} sx={{ pt: 0 }} />
      <Tab icon={<ForumOutlined />} sx={{ pt: 0 }} />
      <Tab icon={<Redeem />} sx={{ pt: 0 }} />
      
    </Tabs>
    <ArtistFab />
    </>
    
  );
}
