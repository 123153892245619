import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  Card,
  CardMedia,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";

import { ArrowBackIos } from "@mui/icons-material";

import ReactPlayer from "react-player";

import CoverDefault from "../assets/cover-default.jpg";

import ArtistLogo from "../fragments/ArtistLogo";
import NotebookIcon from "../fragments/NotebookIcon";

import { API_BASE, artistKey, currentUser } from "../App";

export default function Subscribe() {
  const [loading, setLoading] = useState(true);
  const [artist, setArtist] = useState(null);

  const [country, setCountry] = useState("US"); // Default to US
  const [limited, setLimited] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchArtist = async () => {
      try {
        const endpoint = API_BASE + "/artist";
        const response = await axios.get(endpoint, {
          params: {
            artist_key: artistKey, // artistKey from localStorage not from App.js
          },
        });

        setArtist(response.data.artist);
      } catch (error) {
        console.error("Error fetching artist:", error);
        // Set artistKey to null and navigate to /subscriptions on error
        localStorage.removeItem("artistKey"); // Assuming you're storing artistKey in localStorage
        // setArtistKey();
        navigate("/subscriptions");
      } finally {
        setLoading(false);
      }
    };

    if (artistKey) {
      fetchArtist();
    }
  }, [artistKey, navigate]);

  useEffect(() => {
    const checkSubscribed = async () => {
      const endpoint = API_BASE + "/subscribed";
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
          artist_key: artistKey,
        },
      });
      const isSubscribed = response.data.subscribed;
      console.log("subscribed", isSubscribed);
      if (isSubscribed) {
        navigate("/" + artistKey);
      }
    };
    if (artistKey) {
      checkSubscribed();
    }
  }, [artistKey]);

  const handleSubscribe = async () => {
    setLoading(true);

    const endpoint = API_BASE + "/subscribe";
    try {
      const response = await axios.get(endpoint, {
        params: {
          artist_key: artist.key,
          user_key: currentUser.key,
          limited: limited,
        },
      });
      const checkoutUrl = response.data.url;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
      alert(
        "Error redirecting to checkout. Please contact support@notebook.fm"
      );
    } finally {
      setLoading(false);
    }
  };

  const checkLocation = async () => {
    try {
      const endpoint = API_BASE + "/location";
      const response = await axios.get(endpoint);
      setLimited(response.data.limited);
      setCountry(response.data.country);

      console.log("Location:", response.data);
    } catch (error) {
      console.error("Error checking location:", error);
    }
  };

  useEffect(() => {
    checkLocation();
  }, []);

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <PageAppBar />

      {!artist && (
        <Box>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="50vh"
            // sx={{ borderRadius: "20px", my: 4}}
          />
          <Box sx={{ px: 2 }}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height="300px"
              sx={{ borderRadius: "20px", my: 2 }}
            />
          </Box>
        </Box>
      )}

      {artist && (
        <>
          <ArtistHeader artist={artist} />

          {!limited && (
            <SubscribeCard
              artist={artist}
              handleSubscribe={handleSubscribe}
              loading={loading}
            />
          )}

          {limited && artist?.meta.pocketbook === "on" && (
            <LimitedSubscribeCard
              artist={artist}
              handleSubscribe={handleSubscribe}
              loading={loading}
              country={country}
            />
          )}

          {limited && artist?.meta.pocketbook !== "on" && (
            <WaitlistCard artist={artist} loading={loading} country={country} />
          )}
        </>
      )}
    </Box>
  );
}

function WaitlistCard({ artist, loading, country }) {
  const handleWaitlist = async () => {
    alert(
      "You have been added to the waitlist. We will notify you when we launch in your country."
    );
    window.location.href = "/subscriptions";
  };

  return (
    <Card
      sx={{
        m: 2,
        p: 2,
        // py: 4,
        // px: 2,
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }} color="lightgray">
          Join waitlist for {artist?.name}
        </Typography>
        {/* <NotebookIcon/> */}
      </Box>

      <Typography variant="caption">
        It looks like you're accessing Notebook from outside of our service area
        ({country}). Licensing music protects artists and songwriters, which is
        core to our mission. We're launching in new countries in the coming
        months. In the meantime, join the waitlist to get notified when we
        launch in your country.
      </Typography>

      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        startIcon={<NotebookIcon opacity={!artist?.verified ? 0.5 : 1} />}
        style={{ borderRadius: "20px" }}
        onClick={handleWaitlist}
        disabled={loading || !artist || !artist?.verified}
      >
        Join Waitlist
      </Button>
    </Card>
  );
}

function LimitedSubscribeCard({ artist, handleSubscribe, loading, country }) {
  return (
    <Card
      sx={{
        m: 2,
        p: 2,
        // py: 4,
        // px: 2,
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <NotebookIcon size={14} />
        <Typography variant="body1" color="lightgray" sx={{ p: 0 }}>
          Pocketbook
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }} color="lightgray">
          Subscribe to {artist?.name}
        </Typography>
        {/* <NotebookIcon/> */}
      </Box>

      <Typography variant="caption">
        It looks like you're accessing Notebook from a country ({country}) in
        which we don't currently have a license to stream music. Licensing music
        protects artists and songwriters, which is core to our mission. We're
        working hard to get licenses in more countries.
      </Typography>
      <Typography variant="caption">
        In the meantime, you can subscribe to my Pocketbook for notes, forums,
        media, merch, meetups, and more — but you won't be able to stream any
        music.
      </Typography>

      <Typography variant="body1">
        <span
          style={{
            // fontSize: "1.2rem",
            marginRight: "10px",
            textDecoration: "line-through",
            opacity: 0.5,
          }}
        >
          ${artist?.meta?.rate}.00
        </span>
        <span style={{ fontSize: "2rem", marginRight: "10px" }}>
          ${artist?.meta?.limited || 2}.00
        </span>
        per month
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        startIcon={<NotebookIcon opacity={!artist?.verified ? 0.5 : 1} />}
        style={{ borderRadius: "20px" }}
        onClick={handleSubscribe}
        disabled={loading || !artist || !artist?.verified}
      >
        Subscribe
      </Button>
    </Card>
  );
}

function SubscribeCard({ artist, handleSubscribe, loading }) {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        m: 2,
        gap: 0,
        p: 4,
        borderRadius: "20px",
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 600 }} color="lightgray">
        Subscribe to {artist?.name}
      </Typography>
      <Typography variant="body1">
        <span style={{ fontSize: "2rem", marginRight: "5px" }}>
          ${artist?.meta?.rate}.00
        </span>
        per month
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        startIcon={<NotebookIcon opacity={!artist?.verified ? 0.5 : 1} />}
        style={{ borderRadius: "20px" }}
        onClick={handleSubscribe}
        disabled={loading || !artist || !artist?.verified}
        sx={{ my: 2 }}
      >
        Subscribe
      </Button>
    </Card>
  );
}

function ArtistHeader({ artist }) {
  return (
    <Box>
      {artist?.meta.welcome && (
        <ReactPlayer
          url={artist?.meta.welcome}
          light={artist?.meta.welcome_preview}
          // playing
          controls
          width="100%"
          height="50vh"
        />
      )}

      {!artist?.meta.welcome && (
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            maxHeight: "50vh",
            overflow: "hidden",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: "100%", // Full width
              height: "100%", // Full height
              objectFit: "cover", // Maintains aspect ratio while covering the container
              backgroundPosition: "center", // Centers the image
            }}
            src={artist?.meta.cover || artist?.meta.profile || CoverDefault}
          />
        </Box>
      )}
    </Box>
  );
}

function PageAppBar() {
  const navigate = useNavigate();

  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <IconButton onClick={() => navigate("/subscriptions")}>
            <ArrowBackIos />
          </IconButton>
        </Box>

        {/* <AppLogo /> */}
        <ArtistLogo />
        <Box style={{ width: "40px" }} />
      </Toolbar>
    </AppBar>
  );
}
