import React, { useState } from "react";

import Box from "@mui/material/Box";

import PollCard from "@/components/PollCard";
import PollResultCard from "@/components/PollResultCard";

export default function PollContainer({ content, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [voted, setVoted] = useState(false);

  return (
    <Box sx={{ my: 2 }}>
      {!voted && <PollCard content={content} setVoted={setVoted} />}
      {voted && <PollResultCard content={content} />}
    </Box>
  );
}
