import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  CardMedia,
  IconButton,
  List,
  Skeleton,
  Typography,
} from "@mui/material";

import { Edit, PlayCircle } from "@mui/icons-material";
import { API_BASE, artistKey, currentUser } from "../../App";

import BottomTabs from "../../components/BottomTabs";

import { MusicContext } from "../../contexts/MusicContext";

import MusicListItem from "../../components/MusicListItem";

import AppIcon from "../../assets/app-icon.svg";
import MainAppBar from "../../components/MainAppBar";
import MusicContainer from "../ArtistVault/MusicContainer";

export default function Media() {
  const navigate = useNavigate();

  const {
    music,
    musicCount,
    musicLoading,
    handlePlay,
    subscribed,
    setSubscribed,
  } = useContext(MusicContext);

  const [contentKey, setContentKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [artist, setArtist] = useState(null);
  const [artistContent, setArtistContent] = useState(null);
  const [cover, setCover] = useState(null);

  useEffect(() => {
    const checkSubscribed = async () => {
      const endpoint = API_BASE + "/subscribed";
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
          artist_key: artistKey,
        },
      });
      const isSubscribed = response.data.subscribed;
      console.log("subscribed", isSubscribed);
      if (isSubscribed) {
        setSubscribed(true);
        setLoading(false);
      } else {
        navigate("/subscribe");
      }
    };
    if (currentUser && artistKey) {
      checkSubscribed();
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchArtist = async () => {
      const endpoint = `${API_BASE}/artist`; // Adjusted to match the new Flask route
      try {
        const response = await axios.get(endpoint, {
          params: { artist_key: artistKey, user_key: currentUser.key },
        });
        setArtist(response.data.artist); // write this to local? hmmmm
        setLoading(false);
      } catch (error) {
        console.error("Error fetching artist vault:", error);
        // Optionally, handle error (e.g., set an error state, show a message to the user)
      }
    };
    fetchArtist();
  }, []);

  return (
    <Box
      sx={{
        pb: 10,
      }}
    >
      <MainAppBar />

      <Box id="main-feed" sx={{ p: 2 }}>
        {!artist && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="358px"
            style={{ borderRadius: "15px" }}
            sx={{ my: 1 }}
          />
        )}

        {artist && (
          <Box id="cover-photo" sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              sx={{
                borderRadius: "15px",
                my: 1,
                height: "360px",
              }}
              image={artist.meta.artwork || AppIcon}
            />

            <input
              accept="image/*"
              style={{ display: "none" }}
              id="cover-photo-input"
              type="file"
              onChange={(e) => setCover(e.target.files[0])}
            />
            {currentUser.key === artist.key && (
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 8,
                  left: 8,
                  opacity: 0.8,
                }}
                onClick={() => navigate("/profile")}
              >
                <Edit />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      <MusicContainer />
      {musicCount == 0 && (
        <Box sx={{px: 2}}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
            
          >
            <Typography variant="h6" sx={{ my: 2, fontWeight: 800 }}>
              Music
            </Typography>
            <IconButton
              color="secondary"
              onClick={() => handlePlay(music[0])}
              sx={{ px: 0 }}
              disabled={true}
            >
              <PlayCircle style={{ fontSize: 50 }} />
            </IconButton>
          </Box>

          <Typography variant="body1" sx={{ color: "darkgray" }}>
            No music found.
          </Typography>
        </Box>
      )}
      <BottomTabs />
    </Box>
  );
}
