import { React } from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";


import SimpleAppBar from "../fragments/SimpleAppBar";




export default function Contact() {
  return (
    <Box>
      <SimpleAppBar />

      <Box sx={{ p: 2 }}>
        <Typography variant="h6" >
          Contact 
        </Typography>
        <Typography>
          support@notebook.fm
        </Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Press Inquiries
        </Typography>
        <Typography>
          press@notebook.fm
        </Typography>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Artists
        </Typography>
        <Typography>
          Please reach out to Julia Michaels 
        </Typography>
        
      </Box>
    </Box>
  );
}
