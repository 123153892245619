import axios from "axios";
import { React, useEffect, useState } from "react";

import {
  Box,
  Typography,
  Skeleton,
  List,
  ListItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import ReplayIcon from "@mui/icons-material/Replay";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import ActionDrawer from "../../components/ActionDrawer";

import NotesIcon from "../../fragments/NotesIconWhite.js";
import { API_BASE, currentUser } from "../../App.js";
import { Autorenew, Headphones, PlayArrow } from "@mui/icons-material";
import { fromNow } from "../../utils/index.js";

export default function SongsContainer() {
  const [loading, setLoading] = useState(true);
  const [songs, setSongs] = useState(null);

  const fetchSongs = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/data/listener";
    const response = await axios.get(endpoint, {
      params: { artist_key: currentUser.key },
    });
    const sortedSongs = response.data.results.sort(
      (a, b) => b.total_plays - a.total_plays
    );
    setSongs(sortedSongs);
    // setSongs(response.data.music);
    setLoading(false);
  };

  useEffect(() => {
    fetchSongs();
  }, []);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Box id="song-content" sx={{ py: 4 }}>
      <Box sx={{ px: 2, mt: 4 }}>
        {/* <Typography variant="h6" color="primary">Listener Data</Typography> */}
        <Box
          sx={{
            my: 2,
            // px: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <NotesIcon sx={{ mr: 4 }} />
          <Typography variant="h6" color="white">
            Music Data
          </Typography>
          <Tooltip
            title="Unique listeners, total plays, and total skips for each song since it was added."
            open={tooltipOpen}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            arrow
          >
            <IconButton
              aria-label="info"
              onClick={handleTooltipOpen}
              onMouseLeave={handleTooltipClose}
              // sx={{ ml: 2 }}
              color="info"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {loading && (
        <Box>
          <Skeleton
            variant="rectangular"
            height={64}
            sx={{ my: 1, px: 2 }}
            style={{ borderRadius: "5px" }}
          />
          {/* <Skeleton variant="rectangular" height={64} sx={{mb: 1}} style={{borderRadius: '10px'}} /> */}
        </Box>
      )}

      {songs?.length === 0 && (
        <Typography variant="body1" sx={{ p: 2 }}>
          No data available
        </Typography>
      )}
      <List>
        {songs?.map((song, index) => (
          <ListItem key={index} sx={{ my: 1 }}>
            <Box style={{ width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {song.title}
              </Typography>
              <Typography variant="caption" sx={{ opacity: 0.7, ml: 1 }}>
                {fromNow(song.created)}
              </Typography>
              </Box>

              
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  opacity: 0.7,
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Headphones sx={{ fontSize: "16px", mr: 1 }} />
                  <Typography variant="caption">
                    {song.distinct_user_count.toLocaleString()} listeners
                  </Typography>
                </Box>
                <Box
                  id="play-stats"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PlayArrow sx={{ fontSize: "16px", mr: 1 }} />
                  <Typography variant="caption">
                    {song.total_plays.toLocaleString()} plays
                  </Typography>
                </Box>

                <Box
                  id="skip-stats"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SkipNextIcon sx={{ fontSize: "16px", mr: 1 }} />
                  <Typography variant="caption">
                    {song.total_nexts.toLocaleString()} nexts
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
