import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";

import {
  ArrowUpward,
  Close,
  LiveTv,
  PlayArrow,
  PlayCircle,
} from "@mui/icons-material";

import ContentTextField from "./ContentTextField";

import { API_BASE, authHeaders, currentUser, artistKey } from "@/App";

export default function NewStreamContainer() {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const localVideoRef = useRef(null); // Ref for the video element
  const navigate = useNavigate();

  useEffect(() => {
    const startPreview = async () => {
      try {
        // Access the camera
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        // Assign the stream to the video element
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing media devices:", err);
      }
    };

    startPreview();

    return () => {
      // Cleanup: Stop the video tracks when component unmounts
      if (localVideoRef.current && localVideoRef.current.srcObject) {
        const stream = localVideoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "live");
    formData.append("meta", JSON.stringify({ text }));

    try {
      const endpoint = API_BASE + "/broadcast";
      const response = await axios.post(endpoint, formData, authHeaders);
      navigate("/live");
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageAppBar handleSubmit={handleSubmit} loading={loading} />
      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField
          text={text}
          setText={setText}
          placeholder="Let your audience know what you'll share in your livestream. This will be sent as SMS to your subscribers to let them know you're going live."
        />

        {/* Media preview box */}
        <Box
          sx={{
            borderRadius: "20px",
            overflow: "hidden",
            border: "1px solid #343332",
            aspectRatio: "1 / 1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <video
            ref={localVideoRef}
            autoPlay
            playsInline
            muted
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "scaleX(-1)", //Yields Mirror Image
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function PageAppBar({ handleSubmit, loading }) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "60px" }}>
          <IconButton onClick={() => window.location.reload()}>
            <Close style={{ color: "darkgray" }} />
          </IconButton>
        </Box>

        <Box
          className="artist-logo"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LiveTv color="secondary" />
          <Typography
            style={{
              fontFamily: "'Montserrat Alternates', sans-serif",
              fontWeight: 600,
              fontSize: "1.1rem",
              marginBottom: "0px",
              marginLeft: "12px",
            }}
          >
            {artistKey || "notebook"} live
          </Typography>
        </Box>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleSubmit}
          disabled={loading}
          // endIcon={<LiveTv />}
          sx={{
            borderRadius: "30px",
          }}
        >
          Start
        </Button>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}
