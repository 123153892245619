import React, { useState } from "react";
import axios from "axios";

import {
  AppBar,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  LinearProgress,
  Link,
  Avatar,
  DialogContent,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";

import { Add, Replay, Verified } from "@mui/icons-material";
import { API_BASE, artistKey, currentUser, authHeaders } from "../App";
import { setCurrentUser } from "../utils";

export default function ReviewAppBar() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showComponent = () => {
    if (
      currentUser &&
      currentUser.role == "artist" &&
      currentUser.public &&
      !currentUser["verified"] &&
      currentUser.key === artistKey
    ) {
      return true;
    }

    return false;
  };

  const refreshCurrentUser = async () => {
    setLoading(true);
    try {
      const endpoint = API_BASE + "/user/current";
      const response = await axios.post(
        endpoint,
        {
          key: currentUser.key,
        },
        authHeaders
      );

      setCurrentUser(response.data.user);

      await new Promise((r) => setTimeout(r, 500));
      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showComponent() && (
        <>
          <AppBar
            position="sticky"
            elevation={0}
            style={{ background: "rgba(36,36,36,.9)" }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 1,
              }}
            >
              <Box sx={{ width: 60 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Verified
                  color="secondary"
                  sx={{ mr: 1, opacity: 0.7 }}
                  style={{ fontSize: "1.2rem" }}
                  onClick={() => setOpen(true)}
                />
                <Typography variant="body">In Review</Typography>
              </Box>

              <Button
                variant="text"
                style={{ color: "darkgray" }}
                onClick={() => setOpen(true)}
              >
                Help
              </Button>
            </Toolbar>
          </AppBar>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Typography variant="h6">Your account in under review</Typography>
              <Typography sx={{ my: 2 }}>
                We'll be in touch as soon as your account is approved.
              </Typography>

              <Typography sx={{ my: 2 }}>
                In the meantime you can continue to post and customize your
                Notebook.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "flex-start", my: 3 }}>
                <IconButton
                  onClick={() => (window.location.href = "/post")}
                  style={{ background: "#9A5AEF" }}
                  size="small"
                  sx={{ mr: 2, my: 1 }}
                >
                  <Add />
                </IconButton>

                <Typography>
                  You can now{" "}
                  <Link href="/post" color="secondary">
                    create a new post
                  </Link>{" "}
                  by clicking the (+) button.
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "flex-start", my: 3 }}>
                <Avatar
                  onClick={() => (window.location.href = "/profile")}
                  src={currentUser.meta.profile}
                  sx={{ mr: 2, my: 1 }}
                  style={{
                    border: 1,
                    borderColor: "#04FFFF",
                    borderStyle: "solid",
                    width: 34,
                    height: 34,
                  }}
                />
                <Typography>
                  Visit{" "}
                  <Link href="/profile" color="secondary">
                    My Profile
                  </Link>{" "}
                  to customize your Notebook (pricing, cover photo, etc.)
                </Typography>
              </Box>

              <Typography sx={{ my: 2 }}>
                Contact artists@notebook.fm with any questions.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button color="secondary" onClick={() => setOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
