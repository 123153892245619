import React, { useState } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send";

import TermsAndConditions from "../../fragments/TermsAndConditions";
import PrivacyPolicy from "../../fragments/PrivacyPolicy";

import { setCurrentUser, setToken } from "../../utils";
import { API_BASE, artistKey } from "../../App";
import AutoStories from "@mui/icons-material/AutoStories";

export default function NewUserContainer({
  countryCode,
  phone,
  loading,
  setLoading,
}) {
  const [name, setName] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  const [profileImage, setProfileImage] = useState(null);

  const formattedPhone = () => {
    return countryCode + phone;
  };

  const handleCreateUser = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("phone", formattedPhone());
      formData.append("name", name);
      formData.append("terms", agreeToTerms.toString());
      if (profileImage) {
        formData.append("profile", profileImage);
      }

      const response = await fetch(`${API_BASE}/user/register`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.user) {
        setCurrentUser(data.user);
        setToken(data.token);
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (artistKey) {
          window.location.href = "/subscribe";
        } else {
          window.location.href = "/subscriptions";
        }
      } else {
        setLoading(false);
        alert(data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error creating user:", error);
      alert(
        "An error occurred while creating the account. Please contact support@notebook.fm"
      );
      //   window.location.reload();
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ my: 2, px: 4 }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              src={profileImage ? URL.createObjectURL(profileImage) : null}
              sx={{ width: 150, height: 150 }}
            />
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="profile"
              type="file"
              onChange={(e) => setProfileImage(e.target.files[0])}
            />
            <label htmlFor="profile">
              <Button
                startIcon={<EditIcon />}
                variant="text"
                color="secondary"
                component="span"
              >
                Profile
              </Button>
            </label>
          </Box>
        </Box>

        <TextField
          label="Name"
          variant="outlined"
          required
          // helperText="Your full name. ID verification may be required."
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          autoComplete="off"
          sx={{ my: 4 }}
          InputProps={{
            style: {
              borderRadius: "30px",
            },
          }}
        />

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
          sx={{ mb: 4 }}
        >
          <Checkbox
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
            sx={{ p: 0, mr: 1, mt: 0.5}}
          />
          <Typography variant="body1" color="darkgray">
            I agree to the{" "}
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setTermsOpen(true)}
            >
              Terms and Condtions
            </span>
            <span>, the </span>
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setPrivacyOpen(true)}
            >
              Privacy Policy,
            </span>
            <span> and to treat everyone with love and respect.</span>
            
          </Typography>
        </Box>

        <Button
          variant="contained"
          size="large"
          fullWidth
          onClick={handleCreateUser}
          disabled={name.length < 3 || loading || !agreeToTerms}
          startIcon={<AutoStories />}
          style={{ margin: "10px 0", borderRadius: "20px" }}
        >
          OPEN NOTEBOOK
        </Button>
      </Box>
      <TermsAndConditionsDrawer
        open={termsOpen}
        onClose={() => setTermsOpen(false)}
      />
      <PrivacyPolicyDrawer
        open={privacyOpen}
        onClose={() => setPrivacyOpen(false)}
      />

    </Box>
  );
}

function TermsAndConditionsDrawer({ open, onClose }) {
  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box p={2} style={{ height: "90vh" }}>
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose} style={{ float: "right" }}>
            <ExpandMoreIcon style={{ fontSize: "30px" }} />
          </IconButton>
        </Box>
        <TermsAndConditions />
      </Box>
    </Drawer>
  );
}

function PrivacyPolicyDrawer({ open, onClose }) {
  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box p={2} style={{ height: "90vh" }}>
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose} style={{ float: "right" }}>
            <ExpandMoreIcon style={{ fontSize: "30px" }} />
          </IconButton>
        </Box>
        <PrivacyPolicy />
      </Box>
    </Drawer>
  );
}
