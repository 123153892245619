import React, { useState } from "react";
import axios from "axios";

import {
  AppBar,
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  LinearProgress,
  Toolbar,
} from "@mui/material";

import { ArrowUpward, Close, Poll } from "@mui/icons-material";

import ContentTextField from "./ContentTextField";

import { API_BASE, authHeaders, currentUser } from "../../App";

export default function PollContainer() {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const [options, setOptions] = useState(["", "", ""]);
  const [question, setQuestion] = useState("");

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "poll");

    formData.append(
      "meta",
      JSON.stringify({
        text: text,
        question: question,
        options: options,
      })
    );

    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageAppBar handleSubmit={handleSubmit} loading={loading} />
      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField text={text} setText={setText} />
        <Card id="poll-container" style={{ borderRadius: "20px" }}>
          <CardContent>
            <TextField
              fullWidth
              label="Poll Question"
              value={question}
              autoComplete="off"
              variant="standard"
              color="secondary"
              style={{ marginBottom: "20px" }}
              onChange={(e) => setQuestion(e.target.value)}
            />

            {options.map((option, index) => (
              <TextField
                key={index}
                fullWidth
                size="small"
                label={`Option ${index + 1}`}
                value={option}
                style={{ margin: "10px 0" }}
                InputProps={{ style: { borderRadius: "30px" } }}
                onChange={(e) => {
                  const newOptions = [...options];
                  newOptions[index] = e.target.value;
                  setOptions(newOptions);
                }}
              />
            ))}
          </CardContent>
        </Card>
        
      </Box>
    </Box>
  );
}

function PageAppBar({ handleSubmit, loading }) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => window.location.reload()}>
          <Close style={{ fontSize: "2.0rem", color: "darkgray" }} />
        </IconButton>

        <Box style={{ display: "flex", alignItems: "center" }}>
          <Poll sx={{ mr: 1 }} color="secondary" />
          <Typography variant="h6" style={{ color: "white" }}>
            New Poll
          </Typography>
        </Box>
        <IconButton
          size="small"
          style={{
            background: "#9A5AEF",
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          <ArrowUpward />
        </IconButton>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}
