import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export default function TermsAndConditions() {
  const [termsHtml, setTermsHtml] = useState("");

  useEffect(() => {
    fetch("/terms.html")
      .then((response) => response.text())
      .then((data) => {
        setTermsHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching terms:", error);
      });
  }, []);

  return (
    <Box>
      <div
          style={{
            color: "white",
            backgroundColor: "rgba(256,256,256,0.95)",
            padding: "30px",
            borderRadius: "20px",
          }}
        >
        <div dangerouslySetInnerHTML={{ __html: termsHtml }} />
      </div>
    </Box>
  );
}
