import axios from "axios";
import React, { useState } from "react";

import {
  Box,
  Card,
  CardMedia,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  LinearProgress,
} from "@mui/material";

import { ArrowBackIos, ArrowUpward, Cancel, Close, Image, PhotoCamera } from "@mui/icons-material";

import { API_BASE, authHeaders, currentUser } from "../../App";

import ContentTextField from "./ContentTextField";
// import PageAppBar from "./PageAppBar";

export default function Post({ file }) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "image");

    formData.append("file", file);

    const meta = {
      text: text,
    };

    formData.append("meta", JSON.stringify(meta));

    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageAppBar handleSubmit={handleSubmit} loading={loading} />

      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2
        }}
      >
        <ContentTextField text={text} setText={setText} />
        <Card style={{ borderRadius: "20px" }}>
          <CardMedia
            component="img"
            src={file ? URL.createObjectURL(file) : ""}
            style={{ borderRadius: "10px" }}
          />
        </Card>
        
      </Box>
    </Box>
  );
}


function PageAppBar({  handleSubmit, loading }) {
  return (
    <AppBar
        position="sticky"
        elevation={0}
        style={{ background: "rgba(19,19,19,.9)" }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={() => window.location.reload()}>
            <Close style={{ fontSize: "2.0rem", color: 'darkgray' }} />
          </IconButton>
          <Box style={{ display: "flex", alignItems: "center" }}>
          <PhotoCamera sx={{ mr: 1 }} color="secondary" />
          <Typography variant="h6" style={{ color: "white" }}>
            New Photo
          </Typography>
        </Box>

          <IconButton
            size="small"
            style={{
              background: "#9A5AEF",
            }}
            onClick={handleSubmit}
            disabled={loading}
          >
            <ArrowUpward />
          </IconButton>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
  );
}