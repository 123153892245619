import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { BarChart } from "@mui/x-charts/BarChart"; // Import the BarChart component

import { API_BASE, currentUser } from "../../App.js";

export default function AgeContainer() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    setLoading(true);
    try {
      const endpoint = API_BASE + "/data/age";
      const response = await axios.get(endpoint, {
        params: { artist_key: currentUser.key },
      });
      const sortedResults = response.data.results.sort(
        (a, b) => parseInt(a.age) - parseInt(b.age)
      );
      setResults(sortedResults);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching age data:", error);
      setLoading(false);
    }
  };

  const maxAge =
    results.length > 0
      ? Math.max(...results.map((item) => parseInt(item.age)))
      : 0;

  return (
    <Card style={{ borderRadius: "10px" }} sx={{p: 2, my: 2}}>
      <CardContent>
        {loading ? (
          <Box>
            <Skeleton variant="rectangle" height={200} sx={{ mt: 1 }} />
          </Box>
        ) : (
          <>
            {results.length > 0 ? (
              <Box sx={{ height: 200 }}>
                <BarChart
                  margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
                  // slotProps={{
                  //   legend: {
                  //     hidden: true,
                  //   },
                  // }}
                  xAxis={[
                    {
                      scaleType: "band",
                      min: 0,
                      max: maxAge,
                      data: results.map((item) => parseInt(item.age)),
                    },
                  ]}
                  series={[
                    {
                      data: results.map((item) => item.count),
                      label: "Age",
                    },
                  ]}
                  // height={300}
                />
                
              </Box>
            ) : (
              <Typography variant="body1">No subscribers yet.</Typography>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
