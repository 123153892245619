import React from "react";

import { Box, Fab } from "@mui/material";
import { Add } from "@mui/icons-material";

import { currentUser } from "../App";

export default function ArtistFab() {
  return (
    <>
      {currentUser.role === "artist" && (
        <Box
          sx={{
            position: "fixed",
            bottom: 150,
            right: 5,
            m: 2,
            zIndex: 1000,
            display: window.location.pathname == "/post" ? "none" : "block",
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => (window.location.href = "/post")}
          >
            <Add />
          </Fab>
        </Box>
      )}
    </>
  );
}
