import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { Box, Button, Card, IconButton, Slider } from "@mui/material";

import { Cancel, Pause, PlayArrow } from "@mui/icons-material";

import { calculateBarData, drawBars } from "./helpers";
import { API_BASE } from "../../App";
import { formatSeconds } from "../../utils";

import { TimeRow } from "./NewAudio";

export default function TrimAudio({
  file,
  setFile,
  duration,
  setDuration,
  setTrimming,
}) {
  const audioPostRef = useRef(null);
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [audioUrl, setAudioUrl] = useState("");
  const [barData, setBarData] = useState(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [editing, setEditing] = useState(false);
  const [trim, setTrim] = useState(null);

  useEffect(() => {
    if (!file) return;
    // Revoke the previous URL
    if (audioUrl != "") {
      URL.revokeObjectURL(audioUrl);
    }

    const newUrl = URL.createObjectURL(file);
    setAudioUrl(newUrl);
  }, [file]);

  useEffect(() => {
    setTrim([0, duration]);
  }, [duration]);

  useEffect(() => {
    if (!file) return;

    const audioContext = new AudioContext();
    const reader = new FileReader();

    reader.onload = async (e) => {
      const buffer = await audioContext.decodeAudioData(e.target.result);
      const data = calculateBarData(buffer);
      setBarData(data);
    };

    reader.readAsArrayBuffer(file);
  }, [file]);

  useEffect(() => {
    if (!barData) return;
    drawBars(barData, canvasRef.current);
    // (barData);
  }, [barData]);

  const handlePlayPause = () => {
    if (audioPostRef.current.paused) {
      audioPostRef.current.play();
      setIsPlaying(true);
    } else {
      audioPostRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioPostRef.current) {
      setDuration(audioPostRef.current.duration);
    }
  };

  const handleTimeUpdate = () => {
    if (audioPostRef.current) {
      setCurrentTime(audioPostRef.current.currentTime);
    }
  };

  useEffect(() => {
    if (!canvasRef.current || !barData) return;
    if (!editing) {
      drawBars(barData, canvasRef.current, currentTime, duration);
    } else {
      drawBars(barData, canvasRef.current, trim[1], duration, trim[0]);
    }
  }, [currentTime]);

  const handleTrimChange = (e, newValue) => {
    if (newValue[0] !== trim[0]) {
      audioPostRef.current.currentTime = newValue[0];
    } else if (newValue[1] !== trim[1]) {
      audioPostRef.current.currentTime = newValue[1];
    }

    setTrim(newValue);
    // setCurrentTime(newValue[0]);
  };

  const handleSave = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    formData.append("start", Math.floor(trim[0]));
    formData.append("end", Math.floor(trim[1]));

    const endpoint = API_BASE + "/trim-file";
    const response = await axios.post(endpoint, formData, {
      responseType: "blob",
    });
    // setFile(response.data);
    setFile(
      new File([response.data], "trimmed_audio.mp3", { type: "audio/mp3" })
    );
    setTrimming(false);
    setLoading(false);
  };

  return (
    <Card style={{ borderRadius: "15px", padding: "20px" }}>
      <audio
        ref={audioPostRef}
        src={audioUrl}
        controls
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
        style={{ display: "none" }}
      />

      <Box>
        <Box
          id="button-row"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <IconButton onClick={() => setTrimming(false)}>
            <Cancel color="info" />
          </IconButton>

          <Button
            onClick={handleSave}
            disabled={
              loading || (trim && trim[0] === 0 && trim[1] === duration)
            }
            variant="outlined"
            size="small"
            style={{ borderRadius: "25px" }}
          >
            SAVE
          </Button>
        </Box>

        <Box style={{opacity: loading ? 0.5 : 1}}>
          <canvas ref={canvasRef} style={{ width: "100%", height: "100px" }} />

          <Slider
            id="current-time-slider"
            value={currentTime}
            color="secondary"
            onChange={(e, newValue) => {
              if (audioPostRef.current) {
                audioPostRef.current.currentTime = newValue;
              }
            }}
            max={duration}
            // sx={{
            //   "& .MuiSlider-thumb": {
            //     display: "none",
            //   },
            // }}
            style={{ marginTop: "-50px" }}
          />
          <TimeRow currentTime={currentTime} duration={duration} />

          <Slider
            value={trim}
            onChange={handleTrimChange}
            valueLabelDisplay="auto"
            min={0}
            max={duration}
            valueLabelFormat={(value) => formatSeconds(value)}
            style={{ marginTop: "10px" }}
          />

          {/* Button Row */}
          <Box
            id="button-row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <IconButton onClick={handlePlayPause}>
              {isPlaying ? (
                <Pause style={{ fontSize: "50px" }} />
              ) : (
                <PlayArrow style={{ fontSize: "50px" }} />
              )}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
