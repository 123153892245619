import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";

import {
  Box,
  Card,
  CardMedia,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  LinearProgress,
} from "@mui/material";

import { ArrowUpward, AutoAwesomeMotion, Close } from "@mui/icons-material";

import { API_BASE, authHeaders, currentUser } from "../../App";
import ContentTextField from "./ContentTextField";

export default function Carousel({ files }) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [alertShown, setAlertShown] = useState(false);

  const limitedFilesArray = useMemo(() => {
    const filesArray = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );

    if (filesArray.length > 6 && !alertShown) {
      alert("Carousel supports up to 6 images.");
      setAlertShown(true); // Ensure the alert only shows once
    }

    return filesArray.slice(0, 6);
  }, [files, alertShown]);

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "carousel");

    limitedFilesArray.forEach((file) => {
      formData.append("file", file); // Append each file individually
    });

    const meta = {
      text: text,
    };

    formData.append("meta", JSON.stringify(meta));

    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageAppBar handleSubmit={handleSubmit} loading={loading} />

      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField text={text} setText={setText} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {limitedFilesArray.map((file, index) => (
            <Card key={index} style={{ borderRadius: "20px", flexShrink: 0 }}>
              <CardMedia
                component="img"
                src={URL.createObjectURL(file)}
                style={{ borderRadius: "10px", height: "100px", width: "100px" }}
              />
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

function PageAppBar({ handleSubmit, loading }) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => window.location.reload()}>
          <Close style={{ fontSize: "2.0rem", color: "darkgray" }} />
        </IconButton>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <AutoAwesomeMotion sx={{ mr: 1 }} color="secondary" />
          <Typography variant="h6" style={{ color: "white" }}>
            New Carousel
          </Typography>
        </Box>

        <IconButton
          size="small"
          style={{
            background: "#9A5AEF",
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          <ArrowUpward />
        </IconButton>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}