import axios from "axios";
import React, { useEffect, useState } from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { API_BASE, currentUser } from "../../App.js";

import UserDrawer from "../../components/UserDrawer";

export default function More() {
  const [loading, setLoading] = useState(true);
  const [songs, setSongs] = useState(null);
  const [songCount, setSongCount] = useState(3);
  const [frontRow, setFrontRow] = useState(null);

  const [activeTab, setActiveTab] = useState(0);

  const fetchFrontRow = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/data/front-row";
    const response = await axios.get(endpoint, {
      params: { artist_key: currentUser.key },
    });
    setFrontRow(response.data.front_row);
    setLoading(false);
  };

  useEffect(() => {
    fetchFrontRow();
  }, []);

  // last 7 days as an array of iso strings

  // Styles for the grid container
  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)", // Three columns
    gap: "15px", // Space between images
    my: 2,
    overflow: "scroll",
    "::-webkit-scrollbar": {
      display: "none",
    },
  };

  return (
    <Box id="front-row" sx={{ py: 4, px: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="h6" color="primary">
          Front Row
        </Typography>
      </Box>
      <Typography
        variant="caption"
        style={{ marginTop: "5px", color: "darkgray" }}
      >
        Your most engaged members
      </Typography>

      {frontRow?.length === 0 && (
        <Typography
          variant="body1"
          style={{ color: "darkgray" }}
          sx={{ mt: 2 }}
        >
          No data available
        </Typography>
      )}
      <Box sx={gridContainerStyle}>
        {frontRow?.map((user, index) => (
          <UserAvatar key={index} user={user} />
        ))}
        {loading && (
          <>
            <Skeleton variant="circular" height={60} width={60} />
            <Skeleton variant="circular" height={60} width={60} />
            <Skeleton variant="circular" height={60} width={60} />
          </>
        )}
      </Box>
    </Box>
  );
}

function UserAvatar({ user }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Avatar
        alt={user["name"]}
        src={user.meta.profile}
        sx={{ width: 60, height: 60 }}
        onClick={() => setOpen(true)}
      />
      <UserDrawer userKey={user.key} open={open} setOpen={setOpen} />
    </>
  );
}
