import { useState, useEffect, useRef } from "react";

const usePullToRefresh = (refreshFunction) => {
  const [pullStart, setPullStart] = useState(0);
  const [isPulling, setIsPulling] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const touchStartY = useRef(0);

  const handleTouchStart = (e) => {
    if (window.scrollY === 0) {
      touchStartY.current = e.touches[0].clientY;
      setPullStart(touchStartY.current);
    }
  };

  const handleTouchMove = (e) => {
    if (window.scrollY === 0 && e.touches[0].clientY > touchStartY.current) {
      setIsPulling(true);
    }
  };

  const handleTouchEnd = async () => {
    if (isPulling) {
      setIsRefreshing(true);
      await refreshFunction();
      setIsRefreshing(false);
      setIsPulling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isPulling]);

  return isRefreshing;
};

export default usePullToRefresh;
