import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { Map, Source, Layer } from "react-map-gl";
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
} from "./layers";

import { API_BASE, currentUser } from "../../App.js";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoicGhsZXZlbDciLCJhIjoiY2x0NmRkbmc5MDN6MTJucDZueTVzd3J1bCJ9.LiVcmKEeOk_l5La0vFbcmQ";

export default function LocationsContainer() {
  const mapRef = useRef();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const endpoint = API_BASE + "/data/map";
      const response = await axios.get(endpoint, {
        params: { artist_key: currentUser.key },
      });
      setData(response.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Box id="map-content">
      <style>
        {`.mapboxgl-control-container { display: none !important; }`}
      </style>

      {/* <Box sx={{ px: 2 }}>
        <Typography variant="h6">Locations</Typography>
      </Box> */}

      <Map
        initialViewState={{
          latitude: 40.67,
          longitude: -103.59,
          zoom: 1,
        }}
        style={{ height: 350, marginTop: 20 }}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        mapboxAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={[clusterLayer.id]}
        // onClick={onClick}
        ref={mapRef}
      >
        <Source
          id="subscribers"
          type="geojson"
          // data="https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson"
          data={data}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      </Map>
    </Box>
  );
}
