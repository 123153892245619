import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";

import { MusicContext } from "../../contexts/MusicContext";

import CompactPlayer from "./CompactPlayer";
import FullSizePlayer from "./FullSizePlayer";

export default function MusicPlayer() {
  const { currentSong } = useContext(MusicContext);

  const [compact, setCompact] = useState(false);

  return (
    <Box>
      {currentSong && (
        <>
          {compact ? (
            <CompactPlayer compact={compact} setCompact={setCompact} />
          ) : (
            <FullSizePlayer compact={compact} setCompact={setCompact} />
          )}
        </>
      )}
    </Box>
  );
}
