import axios from "axios";
import React, { useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";

import ActiveContentDrawer from "../ActiveContentDrawer";
import ActionDrawer from "../ActionDrawer";

import CaptionContainer from "../../fragments/CaptionContainer";

import AttendeesDrawer from "./AttendeesDrawer";

import { API_BASE, currentUser } from "../../App";

import { Bookmark, ChatBubbleOutline, Check, EventAvailable } from "@mui/icons-material";

export default function ExperienceCard({ content }) {
  const [favorite, setFavorite] = useState(content.user_favorite);
  const [open, setOpen] = useState(false);

  const handleRSVP = async () => {
    setFavorite(!favorite);
    if (favorite) {
      const endpoint = API_BASE + "/favorites/delete";
      const response = await axios.post(endpoint, {
        user_key: currentUser.key,
        content_key: content.key,
      });
    } else {
      const endpoint = API_BASE + "/event";
      const response = await axios.post(endpoint, {
        user_key: currentUser.key,
        content_key: content.key,
        type: "favorite",
      });
    }
  };

  return (
    <Box sx={{ width: "320px" }}>
      <Card style={{ borderRadius: "20px", margin: "20px 0" }}>
        <CardContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              color="secondary"
              style={{ marginRight: "5px" }}
            >
              ARTIST EXPERIENCE
            </Typography>
            <ActionDrawer content={content} />
          </Box>
          <Typography variant="h6" style={{ marginTop: "-10px" }}>
            {content.meta.title}
          </Typography>
          <Box >
            <Typography
              variant="caption"
              style={{ marginRight: "5px", color: "darkgray" }}
            >
              {content.meta.location} • {content.meta.when}
            </Typography>
          </Box>

          {content.meta.cover && (
            <CardMedia
              component="img"
              height="300"
              image={content.meta.cover}
              style={{ borderRadius: "20px" }}
              sx={{ my: 2 }}
            />
          )}
          

          {(favorite || currentUser.role == "artist") && (
            <AttendeesDrawer content={content} />
          )}

          {content.meta.closed && (
            <Button
              variant="contained"
              color="secondary"
              disabled
              size="small"
              fullWidth
              startIcon={<EventAvailable />}
              style={{ borderRadius: "20px" }}
              sx={{ my: 2 }}
            >
              Experience Complete
            </Button>
          )}

          {!content.meta.closed && (
            <Button
              variant={favorite ? "contained" : "outlined"}
              color="secondary"
              size="small"
              fullWidth
              startIcon={favorite ? <Check /> : <Bookmark />}
              onClick={handleRSVP}
              style={{ borderRadius: "20px" }}
              sx={{ mt: 2 }}
            >
              {favorite ? "Confirmed" : "RSVP"}
            </Button>
          )}

          {!content.meta.closed && content.meta.text && (
            <Box sx={{ mt: 2, mb: 0 }}>
              <CaptionContainer content={content} />
            </Box>
          )}
        </CardContent>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
          sx={{ pt: 0, pb: 2, px: 2 }}
        >
          <Box id="handle-chat">
            <IconButton
              onClick={() => setOpen(true)}
              style={{ marginLeft: "5px" }}
            >
              <ChatBubbleOutline />
            </IconButton>
            <Typography variant="caption" style={{ marginRight: "5px" }}>
              {content.comment_count + 1}
            </Typography>
          </Box>
        </Box>
      </Card>

      <ActiveContentDrawer content={content} open={open} setOpen={setOpen} />
    </Box>
  );
}
