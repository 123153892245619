import axios from "axios";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import CommentSkeleton from "../../fragments/CommentSkeleton";

import ExperiencesContainer from "../ArtistVault/ExperiencesContainer";
import MerchContainer from "../ArtistVault/MerchContainer";

import { API_BASE, artistKey, currentUser } from "../../App";

import BottomTabs from "../../components/BottomTabs";
import MainAppBar from "../../components/MainAppBar";

export default function Notes() {
  const [contentKey, setContentKey] = useState(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [artistContent, setArtistContent] = useState(null);

  const [merch, setMerch] = useState([]);
  const [experiences, setExperiences] = useState([]);

  const fetchMerch = async () => {
    const endpoint = API_BASE + "/merch";
    const response = await axios.get(endpoint, {
      params: {
        user_key: currentUser.key,
        artist_key: artistKey,
      },
    });
    setMerch(response.data.content);
  };

  const fetchExperiences = async () => {
    const endpoint = API_BASE + "/experiences";
    const response = await axios.get(endpoint, {
      params: {
        user_key: currentUser.key,
        artist_key: artistKey,
      },
    });
    setExperiences(response.data.content);
  };

  useEffect(() => {
    fetchMerch();
    fetchExperiences();
    setLoading(false);
  }, []);

  return (
    <Box>
      <MainAppBar />

      <Box sx={{ pb: 15 }}>
        <Box id="main-feed">
          <Box style={{ marginBottom: "100px" }}>
            <MerchContainer artistContent={merch} />
            <ExperiencesContainer artistContent={experiences} />
          </Box>
        </Box>
      </Box>
      <BottomTabs />
    </Box>
  );
}
