import React, { useState } from "react";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import ActiveContentDrawer from "../../components/ActiveContentDrawer";

export default function MerchContainer({ artistContent }) {
  return (
    <Box sx={{ px: 2 }}>
      <Typography variant="h6" sx={{ mt: 4, fontWeight: 800 }}>
        Merch
      </Typography>
      {artistContent?.length == 0 && (
        <Typography variant="body1" color="darkgray" sx={{ my: 2 }}>
         You'll get an SMS when merch is available.
        </Typography>
      )}

      {artistContent?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            overflowX: "scroll",
            scrollbarWidth: "none" /* Firefox */,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {artistContent?.map((content, index) => (
            <Box key={index}>
              <MerchCard content={content} />
              <Typography sx={{ mt: 2 }}>${content.meta.price}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export function MerchCard({ content }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={{ mt: 1, mr: 2 }} onClick={() => setOpen(true)}>
        <Card style={{ borderRadius: "20px", position: "relative" }}>
          <CardMedia
            component="img"
            src={content.meta.cover}
            style={{ borderRadius: "10px", height: "220px", width: "220px" }}
          />
          <Box
            style={{
              position: "absolute", // Changed from "relative" to "absolute"
              bottom: 10, // Adjusted to align to the top
              right: 10, // Adjusted to align to the right
              margin: "8px",
              opacity: 0.8,
              color: "#04FFFF",
            }}
          >
            <ShoppingCartIcon style={{ fontSize: "30px" }} />
          </Box>
        </Card>
      </Box>
      <ActiveContentDrawer content={content} open={open} setOpen={setOpen} />
    </>
  );
}
