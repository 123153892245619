import { React } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import SimpleAppBar from "../fragments/SimpleAppBar";

import Signatures from "../assets/signatures.svg";

export default function About() {
  const navigate = useNavigate();
  return (
    <Box>
      <SimpleAppBar />

      <Box sx={{ p: 2 }}>
        <Typography variant="h6" style={{ fontWeight: 800 }} sx={{ mb: 2 }}>
          Our Story
        </Typography>
        <Typography>
          We are a group of artists and creators who are passionate about
          providing a platform for people to share their most sacred work
          whenever they want, however they want. We aim to create a space where
          artists can connect with their audience, showcase their talent, and
          get real-time feedback, all while making sure collaborators are paid
          fairly. Each subscriber gets us closer to that goal. This is our
          mission.
        </Typography>
        <Typography sx={{ mt: 2 }}>With love,</Typography>
        <img
          src={Signatures}
          alt="Signatures"
          style={{ width: "90%", margin: "20px 0 5px 0" }}
        />
        <Typography sx={{ mt: 0 }}>Julian and Julia</Typography>
      </Box>
    </Box>
  );
}
