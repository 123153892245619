import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Box, Button, Typography } from "@mui/material";

import VideocamIcon from "@mui/icons-material/Videocam";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { API_BASE, currentUser, artistKey } from "../../App";
import { AutoAwesomeMotion } from "@mui/icons-material";

// Styles for the grid container
const gridContainerStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)", // Three columns
  gap: "5px", // Space between images
  mt: 2,
  pl: 0,
};

// Styles for each grid item to ensure they are square
const gridItemStyle = {
  paddingTop: "100%", // 1:1 aspect ratio
  position: "relative",
};

// Styles for the image
const imageStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover", // Keep aspect ratio of image
  // dark overlay on hover
};

export default function MediaContainer({ artistContent }) {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(9);
 
  const handleMediaClick = (content) => {
    navigate(`/media?contentKey=${content.key}`);
    // window.location.href = `/media?contentKey=${content.key}`;
  };

  return (
    <>
      {artistContent?.length > 0 && (
        <Box
          id="media"
          sx={{
            p: 2,
            pb: 8,
            // minHeight: "500px",
            background:
              "linear-gradient(180deg, rgba(18,18,18,0) 50%, rgba(36,36,36,1) 200%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ my: 4, fontWeight: 800 }}>
              Media
            </Typography>
            <Box>
              <IconButton onClick={() => navigate("/media")}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={gridContainerStyle}>
            {artistContent?.slice(0, limit).map((content, index) => (
              <div
                key={index}
                style={gridItemStyle}
                onClick={() => handleMediaClick(content)}
              >
                {content.type == "video" && (
                  <>
                    <img
                      src={content.meta["cover"]}
                      alt={content.title}
                      style={imageStyle}
                      // dark overlay on hover
                      sx={{
                        "&:hover": {
                          filter: "brightness(0.5)",
                        },
                      }}
                    />
                    <Box
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        margin: "8px",
                      }}
                    >
                      <VideocamIcon style={{ fontSize: "20px" }} />
                    </Box>
                  </>
                )}
                {content.type == "carousel" && (
                  <>
                    <img src={content.meta.urls[0]} style={imageStyle} />
                    <Box
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        margin: "8px",
                      }}
                    >
                      <AutoAwesomeMotion style={{ fontSize: "18px" }} />
                    </Box>
                  </>
                )}
                {content.type == "image" && (
                  <img
                    src={content.meta["url"]}
                    alt={content.title}
                    style={imageStyle}
                  />
                )}
              </div>
            ))}
          </Box>
          {
            // Show "Load More" button if there are more than 9 media items
            artistContent?.length > limit && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Button
                  onClick={() => setLimit(limit + 36)}
                  variant="text"
                >
                  Load More
                </Button>
              </Box>
            )
          }
        </Box>
      )}
    </>
  );
}
