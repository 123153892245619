import { React } from "react";

import Box from "@mui/material/Box";

import ArtistProfile from "./ArtistProfile";
import UserProfile from "./UserProfile";

import {currentUser} from "../../App";





export default function Profile() {
  

  return (
    <Box>
      {currentUser.role === "artist" ? <ArtistProfile /> : <UserProfile />}
    </Box>
  );
}

