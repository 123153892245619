import React, { useContext } from "react";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import MusicListItem from "../../components/MusicListItem";

import { MusicContext } from "../../contexts/MusicContext";



export default function MusicContainer({  }) {
  const { music, musicCount, musicLoading, handlePlay } =
    useContext(MusicContext);

  const isLimited = localStorage.getItem("limited") === "true";

  return (
    <>
      {musicCount > 0 && (
        <Box
          id="music"
          sx={{
            p: 2, // Reset any padding
            background:
              "linear-gradient(180deg, rgba(18,18,18,0) 50%, rgba(36,36,36,1) 200%)",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="h6" sx={{ my: 2, fontWeight: 800 }}>
              Music
            </Typography>
            <IconButton
              color="secondary"
              onClick={() => handlePlay(music[0])}
              sx={{ px: 0 }}
              // disabled={isLimited}
            >
              <PlayCircleIcon style={{ fontSize: 50 }} />
            </IconButton>
          </Box>

          <List>
            {music?.map((content, index) => (
              <MusicListItem key={index} content={content} />
            ))}
          </List>

          {musicLoading && (
            <Box
              style={{ display: "flex", justifyContent: "center" }}
              sx={{ p: 4 }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

