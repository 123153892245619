import axios from "axios";
import { useState, useRef, useMemo } from "react";

import {
  AppBar,
  Box,
  Card,
  IconButton,
  Toolbar,
  Typography,
  LinearProgress,
} from "@mui/material";

import { ArrowUpward, Camera, Close, Videocam } from "@mui/icons-material";

import { API_BASE, authHeaders, currentUser } from "../../App";

import ContentTextField from "./ContentTextField";

import ReactPlayer from "react-player";

export default function NewVideo({ file }) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [cover, setCover] = useState(null);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleUpload = async () => {
    const endpoint = API_BASE + "/signed-upload";
    const response = await axios.get(endpoint, authHeaders);
    console.log(response.data);
    const uploadURL = response.data.upload_url;
    const publicURL = response.data.public_url;
    const key = response.data.content_key;
    console.log("uploadURL", uploadURL);
    console.log("publicURL", publicURL);
    console.log("key", key);

    const uploadResponse = await fetch(uploadURL, {
      method: "PUT",
      headers: { "Content-Type": file.type },
      body: file,
    });
    console.log("uploadResponse", uploadResponse);

    return { publicURL, key }; // Return the values to use them directly
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    const { publicURL, key } = await handleUpload();

    const formData = new FormData();
    formData.append("key", key);
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "video");

    // formData.append("file", file);
    formData.append("cover", cover);

    formData.append(
      "meta",
      JSON.stringify({
        text: text,
        url: publicURL,
      })
    );

    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const createThumbnail = async () => {
    console.log("Creating Thumbnail...");
    await new Promise((r) => setTimeout(r, 1000));

    const video = videoRef.current.getInternalPlayer();
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const coverBlob = await new Promise((resolve) => canvas.toBlob(resolve));
    setCover(coverBlob);
    console.log("Thumbnail Created", coverBlob);
  };

  const fileURL = useMemo(() => file && URL.createObjectURL(file), [file]);

  return (
    <Box>
      <PageAppBar handleSubmit={handleSubmit} loading={loading} />

      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField text={text} setText={setText} />
        <Card style={{ borderRadius: "20px" }}>
          <ReactPlayer
            ref={videoRef}
            url={fileURL}
            controls
            width="100%"
            height="auto"
            onReady={createThumbnail}
          />
        </Card>
      </Box>
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </Box>
  );
}

function PageAppBar({ handleSubmit, loading }) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => window.location.reload()}>
          <Close style={{ fontSize: "2.0rem", color: "darkgray" }} />
        </IconButton>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Videocam sx={{ mr: 1 }} color="secondary" />
          <Typography variant="h6" style={{ color: "white" }}>
            New Video
          </Typography>
        </Box>

        <IconButton
          size="small"
          style={{
            background: "#9A5AEF",
          }}
          onClick={handleSubmit}
          disabled={loading}
        >
          <ArrowUpward />
        </IconButton>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}
