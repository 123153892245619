import React from "react";

import spotify from "../assets/spotify.svg";

export default function SpotifyIcon() {
  return (
    <>
      <img src={spotify} alt="Spotify" style={{ height: 22, width: 22 }} />
    </>
  );
}
