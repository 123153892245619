import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export default function PrivacyPolicy() {
  const [privacyHtml, setPrivacyHtml] = useState("");

  useEffect(() => {
    fetch("/privacy.html")
      .then((response) => response.text())
      .then((data) => {
        setPrivacyHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching privacy policy:", error);
      });
  }, []);

  return (
    <Box>
      <div
          style={{
            color: "white",
            backgroundColor: "rgba(256,256,256,0.95)",
            padding: "30px",
            borderRadius: "20px",
          }}
        >
        <div dangerouslySetInnerHTML={{ __html: privacyHtml }} />
      </div>
    </Box>
  );
}
