import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export default function LearnMoreDrawer({ open, onClose }) {
  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box p={2} style={{ height: "90vh" }}>
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose} style={{ float: "right" }}>
            <ExpandMoreIcon style={{ fontSize: "30px" }} />
          </IconButton>
        </Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Verified Artist Setup
        </Typography>
        <Typography variant="body1" sx={{my: 2}}>
          Your profile will remain in Private Mode until you and your management
          team have signed the Notebook Artist Agreement.
        </Typography>
        <Typography variant="body1" sx={{my: 2}}>
          This means that only you will be able to access your profile, and your profile 
          will not be listed anywhere on the platform. 
        </Typography>

        <Typography variant="body1" sx={{my: 2}}>
          Once published, your profile will be available at
          notebook.fm/yourusername.
        </Typography>
      </Box>
    </Drawer>
  );
}
