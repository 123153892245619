import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  AppBar,
  Avatar,
  Button,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@mui/material";

import { Close, Rsvp, Verified } from "@mui/icons-material";

import UserDrawer from "../UserDrawer";

import { API_BASE } from "../../App";

export default function AttendeesDrawer({ content }) {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const endpoint = `${API_BASE}/users`;
        const response = await axios.get(endpoint, {
          params: { content_key: content.key },
        });
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, [open]);

  return (
    <Box>
      <Button
        size="small"
        onClick={() => setOpen(true)}
        startIcon={<Rsvp />}
        color="primary"
        fullWidth
      >
        Show Attendees ({users.length})
      </Button>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { background: "#47596E", borderRadius: "20px 20px 0 0" },
        }}
      >
        <AppBar
          position="sticky"
          elevation={0}
          style={{
            background: "#47596E",
            borderBottom: "1px solid #2E3A4D",
          }}
        >
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              onClick={() => setOpen(false)}
              style={{ margin: "0 0 0 -10px" }}
              color="inherit"
            >
              <Close style={{ fontSize: "30px" }} />
            </IconButton>
            <Typography variant="h6">RSVPs ({users.length})</Typography>

            <Box sx={{ width: "40px" }} />
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm" sx={{ maxHeight: "80vh" }}>
          <List>
            {users.map((user) => (
              <AttendeeListItem key={user.key} user={user} />
            ))}
          </List>
        </Container>
      </Drawer>
    </Box>
  );
}

function AttendeeListItem({ user }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItem
        key={user.key}
        sx={{ my: 2, display: "flex", alignItems: "center" }}
        onClick={() => setOpen(true)}
      >
        <Avatar
          src={user.meta.profile || "/default-profile.png"}
          alt={user.name}
          sx={{ width: 34, height: 34, mr: 2, background: "lightgray" }}
        />
        <Typography>{user.name}</Typography>
        {user.role == "artist" && (
          <Verified
            color={user.verified ? "secondary" : "disabled"}
            style={{ fontSize: "15px"}}
            sx={{ ml: 0.5 }}
          />
        )}

        {user.role == "user" && user.verified && (
          <Verified
            color="primary"
            style={{ fontSize: "15px" }}
            sx={{ ml: 0.5 }}
          />
        )}
      </ListItem>
      <UserDrawer userKey={user.key} open={open} setOpen={setOpen} />
    </>
  );
}
