import {
  Box,
  ButtonBase,
  CardMedia,
  CircularProgress,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { MusicContext } from "../contexts/MusicContext";
import { formatSeconds, getContentArtwork } from "../utils";

export default function MusicListItem({ content }) {
  const { currentSong, handlePlay } = useContext(MusicContext);

  const isLimited = localStorage.getItem("limited") === "true";

  const handleClick = () => {
    handlePlay(content);
  };

  const filteredTags = content.meta.tags
    ? content.meta.tags.filter((tag) => tag.trim() !== "")
    : [];

  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        width: "100%",
        textAlign: "left",
      }}
    >
      <ListItem
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "15px 5px",
          alignItems: "center",
          opacity: isLimited ? 0.6 : 1, // Optionally reduce opacity when disabled
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <CardMedia
            component="img"
            src={getContentArtwork(content)}
            sx={{ width: 40, height: 40, borderRadius: "4px", mr: 1 }}
            alt="Song Cover"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                overflow: "hidden",
                maxWidth: "200px",
              }}
            >
              <Typography
                color={
                  currentSong?.key === content.key ? "secondary" : "inherit"
                }
                noWrap
              >
                {content.meta.title}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="caption" color="textSecondary" noWrap>
                {content.owner.name}
                {filteredTags.length > 0 && `, ${filteredTags.join(", ")}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography sx={{ color: "darkgray" }}>
            {formatSeconds(content.meta.duration)}
          </Typography>
          
        </Box>
      </ListItem>
    </ButtonBase>
  );
}
