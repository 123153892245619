import React from "react";
import Box from "@mui/material/Box";

import Link from "@mui/material/Link";

export default function FooterLinks() {
  return (
    <Box
      id="footer-links"
      sx={{ my: 4, px: 2 }}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        // gap: 20,

      }}
    >
      <Link
        href="/about"
        style={{
          color: "darkgray",
          fontSize: "0.75em",
          // textDecoration: "none",
        }}
        underline="none"
      >
        Our Story
      </Link>
      <span style={{ color: "darkgray", margin: "0 10px" }}> •</span>
      <Link
        href="/Contact"
        style={{
          color: "darkgray",
          fontSize: "0.75em",
          textDecoration: "none",
        }}
      >
        Contact
      </Link>
      <span style={{ color: "darkgray", margin: "0 10px" }}>•</span>
      <Link
        href="/terms"
        style={{
          color: "darkgray",
          fontSize: "0.75em",
          textDecoration: "none",
        }}
      >
        Terms
      </Link>
      <span style={{ color: "darkgray", margin: "0 10px" }}> •</span>
      <Link
        href="/refund"
        style={{
          color: "darkgray",
          fontSize: "0.75em",
          textDecoration: "none",
        }}
      >
        Refunds
      </Link>
      <span style={{ color: "darkgray", margin: "0 10px" }}> •</span>
      <Link
        href="/privacy"
        style={{
          color: "darkgray",
          fontSize: "0.75em",
          textDecoration: "none",
        }}
      >
        Privacy
      </Link>
    </Box>
  );
}


{/* <Typography variant="caption" color="darkgray">
            © 2024 Notebook FM, Inc. All Rights Reserved.
          </Typography> */}