import axios from "axios";
import React, { useState } from "react";

import {
  Box,
  Card,
  Button,
  CardMedia,
  IconButton,
  AppBar,
  LinearProgress,
  Toolbar,
  TextField,
  Typography,
} from "@mui/material";

import {
  Add,
  AddShoppingCart,
  ArrowBackIos,
  ArrowUpward,
  Cancel,
  Close,
} from "@mui/icons-material";

import { API_BASE, authHeaders, currentUser } from "../../App";

import ContentTextField from "./ContentTextField";

export default function Post() {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [price, setPrice] = useState("");
  const [cover, setCover] = useState(null);

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "merch");

    formData.append("cover", cover);

    const meta = {
      text: text,
      title: title,
      link: link,
      price: price,
    };

    formData.append("meta", JSON.stringify(meta));

    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLink = (e) => {
    // make sure the link is a valid url
    setLink(e.target.value);
  };

  const handlePriceChange = (e) => {
    // strip non-numeric characters but allow decimals
    //
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setPrice(value);
  };

  return (
    <Box>
      <PageAppBar
        handleSubmit={handleSubmit}
        loading={loading}
        price={price}
        link={link}
        title={title}
      />

      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField
          text={text}
          setText={setText}
          placeholder="Tell your audience about your new merch. This will be texted to all your subscribers."
        />
        <Card style={{ borderRadius: "20px" }} sx={{ px: 2, mb: 4, py: 4 }}>
          {!cover && (
            <Box>
              <label htmlFor="icon-button-file-merch">
                <Button
                  variant="outlined"
                  color="secondary"
                  component="span"
                  fullWidth
                  style={{ borderRadius: "25px" }}
                  startIcon={<Add />}
                >
                  Add Photo
                </Button>
              </label>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="icon-button-file-merch"
                type="file"
                // multiple
                onChange={(e) => setCover(e.target.files[0])}
              />
            </Box>
          )}

          <CardMedia
            component="img"
            src={cover ? URL.createObjectURL(cover) : ""}
            style={{ borderRadius: "10px" }}
          />

          <TextField
            fullWidth
            autoComplete="off"
            color="secondary"
            label="Price"
            value={price}
            variant="standard"
            onChange={handlePriceChange}
            margin="normal"
            placeholder="Enter the price of the item."
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            required
          />

          <TextField
            fullWidth
            autoComplete="off"
            color="secondary"
            label="Store Link"
            placeholder="https://shop.yourmerchstore.com/item?key=notebook"
            value={link}
            variant="standard"
            onChange={handleLink}
            margin="normal"
            helperText="Add a link to item on your store."
            required
          />

          <TextField
            fullWidth
            autoComplete="off"
            color="secondary"
            label="Title"
            value={title}
            variant="standard"
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            required
          />
        </Card>
      </Box>
    </Box>
  );
}

function PageAppBar({ handleSubmit, loading, price, link, title }) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => window.location.reload()}>
          <Close style={{ fontSize: "2.0rem", color: "darkgray" }} />
        </IconButton>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <AddShoppingCart sx={{ mr: 1 }} color="secondary" />
          <Typography variant="h6" style={{ color: "white" }}>
            New Merch
          </Typography>
        </Box>

        <IconButton
          size="small"
          style={{
            background: "#9A5AEF",
          }}
          onClick={handleSubmit}
          disabled={loading || !price || !link || !title}
        >
          <ArrowUpward />
        </IconButton>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}
