import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";

import { ArrowUpward } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Skeleton,
  TextField,
} from "@mui/material";

import CommentCard from "@/components/CommentCard";

import { MusicContext } from "@/contexts/MusicContext";
import { API_BASE, currentUser } from "@/App";

export default function CommentsContainer({ content, background = "#47596E" }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  const { currentSong, currentTime } = useContext(MusicContext);

  const [newCommentSubmitted, setNewCommentSubmitted] = useState(false);
  const lastCommentRef = useRef(null);
  const previousCommentsRef = useRef([]); // Track previous comments for comparison

  useEffect(() => {
    // Scroll only if comments array actually changes
    if (
      lastCommentRef.current &&
      newCommentSubmitted &&
      JSON.stringify(previousCommentsRef.current) !== JSON.stringify(comments)
    ) {
      lastCommentRef.current.scrollIntoView();
      previousCommentsRef.current = comments; // Update the previous comments reference
    }
  }, [comments]);

  const fetchComments = async () => {
    setLoading(true);
    const endpoint = API_BASE + "/comments";
    const response = await axios.get(endpoint, {
      params: {
        key: content.key,
        user_key: currentUser.key,
      },
    });
    
    const comments = response.data.comments;
    const sortedComments = comments.sort((a, b) => {
      return new Date(a.created) - new Date(b.created);
    });
    // uhh sort replies should happen downstream
    sortedComments.forEach((comment) => {
      comment.replies = comment.replies.sort(
        (a, b) => new Date(a.created) - new Date(b.created)
      );
    });
    setComments(sortedComments);
    setLoading(false);
  };

  // useEffect(() => {
  //   const fetchCommentsInterval = async () => {
  //     await fetchComments();
  //   };

  //   // Initial fetch
  //   fetchCommentsInterval();

  //   // Poll every 2 seconds
  //   const intervalId = setInterval(fetchCommentsInterval, 10000);

  //   // Cleanup function to clear interval when component unmounts
  //   return () => clearInterval(intervalId);
  // }, [content]);

  useEffect(() => {
    fetchComments();
  }, [content]);

  const handleSubmit = async (e) => {
    setSubmitting(true);
    const data = {
      content_key: content.key,
      user_key: currentUser.key,
      type: "comment",
      data: {
        text: comment,
      },
    };

    if (currentTime && currentTime > 0 && content.key === currentSong.key) {
      console.log("Current time: ", currentTime);
      data.data.current_time = currentTime;
    }

    try {
      const endpoint = API_BASE + "/event";
      const response = await axios.post(endpoint, data);
      setComments([...comments, response.data]);
      setNewCommentSubmitted(true);
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setSubmitting(false);
      setComment("");
      fetchComments();
    }
  };

  return (
    <Box
      id="comments-container"
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
      }}
      sx={{ pt: 2 }}
    >
      {loading && (
        <Box sx={{ zIndex: 1000 }}>
          <LoadingSkeleton />
          <LoadingSkeleton />
          <LoadingSkeleton />
        </Box>
      )}

      {!loading && (
        <Box
          style={{
            overflowY: "scroll",
            "::WebkitScrollbar": {
              display: "none",
            },
          }}
        >
          {comments?.map((event, index) => (
            <Box
              key={index}
              sx={{ my: 3 }}
              ref={index === comments.length - 1 ? lastCommentRef : null}
            >
              <CommentCard
                event={event}
                fetchComments={fetchComments}
                content={content}
              />
            </Box>
          ))}
        </Box>
      )}

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          background: background,
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              py: 2,
            }}
          >
            <Avatar
              src={currentUser.meta.profile}
              alt={currentUser.username}
              sx={{ width: 34, height: 34, mt: 0.5 }}
            />
            <TextField
              fullWidth
              multiline
              autoComplete="off"
              maxRows={4}
              size="small"
              placeholder="Join the conversation..."
              variant="outlined"
              value={comment}
              sx={{ mx: 1 }}
              disabled={submitting}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
              onChange={(e) => setComment(e.target.value)}
            />
            <Box>
              <IconButton
                size="small"
                style={{ background: "#9A5AEF" }}
                onClick={handleSubmit}
                disabled={submitting || comment.length === 0}
                sx={{ mt: 0.5 }}
              >
                <ArrowUpward />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}


function LoadingSkeleton() {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      sx={{ mb: 2 }}
    >
      <Skeleton variant="circular" width={60} height={60} />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginLeft: "10px", // Adjust based on actual layout
        }}
      >
        <Skeleton variant="text" width={100} height={30} />
        <Skeleton variant="text" width={250} height={60} />
      </Box>
    </Box>
  );
}
