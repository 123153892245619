import { useState } from "react";
import { Box, IconButton, Typography, Snackbar, Alert } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";

export default function CopyLink({ artist }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const publicUrl = `notebook.fm/${artist.key}`;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(publicUrl);
      setSnackbarOpen(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
      }}
    >
      <IconButton onClick={handleCopy} sx={{ pl: 0 }}>
        <ContentCopy color="primary" />
      </IconButton>
      <Typography variant="body1">{publicUrl}</Typography>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} color="primary" variant="filled">
          Link copied to clipboard
        </Alert>
      </Snackbar>
    </Box>
  );
}
