const barWidth = 1;
const gap = 1;
const height = 100;
const width = 300;

export const calculateBarData = (
  buffer,
  start = 0,
  end = buffer.duration,
  
) => {
  // Calculate the sample indices corresponding to start and end times
  const startIndex = Math.floor(start * buffer.sampleRate);
  const endIndex = Math.floor(end * buffer.sampleRate);

  // Adjust the units and step to only consider the selected time range
  const units = width / (barWidth + gap);
  const bufferLength = endIndex - startIndex; // Only consider the selected range
  const step = Math.floor(bufferLength / units);
  const amp = height / 2;

  let data = [];
  let maxDataPoint = 0;

  for (let i = 0; i < units; i++) {
    const mins = [];
    const maxs = [];

    // Adjust the loop to only iterate over the selected range
    for (let j = 0; j < step && startIndex + i * step + j < endIndex; j++) {
      const datum = buffer.getChannelData(0)[startIndex + i * step + j]; // Access the correct part of the buffer
      if (datum <= 0) {
        mins.push(datum);
      }
      if (datum > 0) {
        maxs.push(datum);
      }
    }

    // Avoid division by zero when there's no data in mins or maxs
    const minAvg = mins.reduce((a, c) => a + c, 0) / (mins.length || 1);
    const maxAvg = maxs.reduce((a, c) => a + c, 0) / (maxs.length || 1);

    const dataPoint = { max: maxAvg, min: minAvg };

    // Track the maximum data point for normalization
    maxDataPoint = Math.max(
      maxDataPoint,
      Math.abs(dataPoint.max),
      Math.abs(dataPoint.min)
    );

    data.push(dataPoint);
  }

  // Normalize the data points to fit within the canvas height
  if (amp * 0.8 > maxDataPoint * amp) {
    const adjustmentFactor = (amp * 0.8) / maxDataPoint;
    data = data.map((dp) => ({
      max: dp.max * adjustmentFactor,
      min: dp.min * adjustmentFactor,
    }));
  }

  return data;
};

export const drawBars = (data, canvas, currentTime, duration, startAfter=0) => {
  const backgroundColor = "transparent";
  const barColor = "darkgray";
  const barPlayedColor = "#04FFFF";
  
  const amp = canvas.height / 2;

  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  if (backgroundColor !== "transparent") {
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  // Convert startAfter to a percentage of the duration
  const startAfterPercent = startAfter / duration;

  const playedPercent = (currentTime || 0) / duration;

  data.forEach((dp, i) => {
    const mappingPercent = i / data.length;
    const played = playedPercent > mappingPercent;
    const beforeStart = mappingPercent < startAfterPercent;

    // Determine bar color based on whether it's played, and before startAfter
    ctx.fillStyle = beforeStart ? barColor : (played ? barPlayedColor : barColor);

    const x = i * (barWidth + gap); // Assuming barWidth and gap are defined somewhere
    const y = amp + dp.min;
    const w = barWidth;
    const h = amp + dp.max - y;

    ctx.beginPath();
    if (ctx.roundRect) {
      // making sure roundRect is supported by the browser
      ctx.roundRect(x, y, w, h, 50); // Assuming a rounded rectangle method or shim exists
      ctx.fill();
    } else {
      // fallback for browsers that do not support roundRect
      ctx.fillRect(x, y, w, h);
    }
  });
};
