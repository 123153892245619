import axios from "axios";
import React, { useState } from "react";

import {
  AppBar,
  Box,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  TextField,
  CardMedia,
  Button,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";

import { Add, ArrowUpward, Close, Event } from "@mui/icons-material";

import ContentTextField from "./ContentTextField";

import { API_BASE, authHeaders, currentUser } from "../../App";

export default function NewExperienceContainer() {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState(""); //
  const [datetime, setDatetime] = useState(new Date());
  const [timezone, setTimezone] = useState("EST");
  const [location, setLocation] = useState("");
  const [link, setLink] = useState("");
  const [when, setWhen] = useState("");
  const [cover, setCover] = useState(null);

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "experience");

    formData.append("cover", cover);

    formData.append(
      "meta",
      JSON.stringify({
        text: text,
        title: title,
        // datetime: datetime.toISOString(),
        // timezone: timezone,
        location: location,
        when: when,
        link: link,
      })
    );

    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <AppBar
        position="sticky"
        elevation={0}
        style={{ background: "rgba(19,19,19,.9)" }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={() => window.location.reload()}>
            <Close style={{ fontSize: "2.0rem", color: "darkgray" }} />
          </IconButton>
          <Box style={{ display: "flex", alignItems: "center" }}>
          <Event sx={{ mr: 1 }} color="secondary" />
          <Typography variant="h6" style={{ color: "white" }}>
            New Experience
          </Typography>
        </Box>

          <IconButton
            size="small"
            style={{
              background: "#9A5AEF",
            }}
            onClick={handleSubmit}
            disabled={loading || !title || !location || !when}
          >
            <ArrowUpward />
          </IconButton>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          mt: 2,
        }}
      >
        <ContentTextField
          text={text}
          setText={setText}
          placeholder="Tell your audience about your new experienece. Message will be texted to all your subscribers."
        />
        <Card id="experience-container" style={{ borderRadius: "20px" }}>
          <CardContent>
            {!cover && (
              <Box>
                <label htmlFor="icon-button-file-merch">
                  <Button
                    variant="outlined"
                    color="secondary"
                    component="span"
                    fullWidth
                    style={{ borderRadius: "25px" }}
                    startIcon={<Add />}
                  >
                    Add Photo
                  </Button>
                </label>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file-merch"
                  type="file"
                  // multiple
                  onChange={(e) => setCover(e.target.files[0])}
                />
              </Box>
            )}

            <CardMedia
              component="img"
              src={cover ? URL.createObjectURL(cover) : ""}
              style={{ borderRadius: "10px" }}
              sx={{ my: 2 }}
            />
            <TextField
              fullWidth
              required
              label="Experience Title"
              value={title}
              autoComplete="off"
              variant="standard"
              color="secondary"
              style={{ marginBottom: "20px" }}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              fullWidth
              required
              label="Location"
              value={location}
              autoComplete="off"
              variant="standard"
              color="secondary"
              style={{ marginBottom: "20px" }}
              onChange={(e) => setLocation(e.target.value)}
            />

            <TextField
              fullWidth
              label="When"
              // type="datetime-local"
              // value={datetime.toISOString().split(":").slice(0, 2).join(":")}
              value={when}
              placeholder="Oct 4th-12th, 2024"
              autoComplete="off"
              variant="standard"
              color="secondary"
              style={{ marginBottom: "20px" }}
              // onChange={(e) => setDatetime(new Date(e.target.value))}
              onChange={(e) => setWhen(e.target.value)}
              required
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
