import axios from "axios";
import { React, useState } from "react";

import {
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Toolbar,
} from "@mui/material";

import { Close, Edit, Instagram } from "@mui/icons-material";

import SpotifyIcon from "../../fragments/SpotifyIcon";

import { API_BASE, authHeaders, currentUser } from "../../App";
import { setCurrentUser } from "../../utils";

export default function Profile() {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  // everyone
  const [name, setName] = useState(currentUser["name"]);
  const [profile, setProfile] = useState(currentUser.meta["profile"] || "");
  const [bio, setBio] = useState(currentUser.meta["bio"] || "");
  const [instagram, setInstagram] = useState(currentUser.meta.instagram || "");
  const [spotify, setSpotify] = useState(currentUser.meta.spotify || "");

  // user data
  const [birthMonth, setBirthMonth] = useState(
    currentUser.meta["birth_month"] || ""
  );
  const [birthDay, setBirthDay] = useState(currentUser.meta["birth_day"] || "");
  const [birthYear, setBirthYear] = useState(
    currentUser.meta["birth_year"] || ""
  );
  const [zipCode, setZipCode] = useState(currentUser.meta["zip_code"] || "");
  const [gender, setGender] = useState(currentUser.meta["gender"] || "");

  const GENDERS = ["Female", "Male", "Other"];

  const handleUpdate = async () => {
    setLoading(true);

    try {
      const userData = {
        name: name,
        key: currentUser.key,
        meta: {
          profile: profile,
          spotify: spotify,
          instagram: instagram,
          bio: bio,
          birth_month: birthMonth,
          birth_day: birthDay,
          birth_year: birthYear,
          zip_code: zipCode,
          gender: gender,
        },
      };

      const endpoint = API_BASE + "/user/update";
      const response = await axios.post(endpoint, userData, authHeaders);
      setCurrentUser(response.data);
      window.location.href = "/";
    } catch (error) {
      alert(`Error updating profile: ${error.message}`);
      console.error("Update failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background:
          "linear-gradient(180deg, rgba(18,18,18,0) 0%, rgba(36,36,36,1) 200%)",
      }}
    >
      <PageAppBar
        loading={loading}
        handleUpdate={handleUpdate}
        name={name}
        uploading={uploading}
      />

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ mt: 15 }} />
        </Box>
      ) : (
        <Box
          sx={{
            px: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, pt: 2 }}>
            <ProfileImageContainer
              profile={profile}
              setProfile={setProfile}
              setUploading={setUploading}
            />

            <TextField
              label="Name"
              variant="outlined"
              value={name}
              required
              fullWidth
              onChange={(e) => setName(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
            />
          </Box>

          <TextField
            label="A Note"
            placeholder="A little bit about me..."
            variant="outlined"
            multiline
            minRows={2}
            maxRows={4}
            value={bio}
            sx={{ my: 2 }}
            fullWidth
            onChange={(e) => setBio(e.target.value)}
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              label={<Instagram color="white" />}
              fullWidth
              placeholder="https://www.instagram.com/YOUR_USERNAME"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
            />
            <TextField
              label={<SpotifyIcon />}
              fullWidth
              placeholder="https://open.spotify.com/artist/YOUR_ID"
              value={spotify}
              onChange={(e) => setSpotify(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
            />
            <TextField
              label="Home Zip Code"
              variant="outlined"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              fullWidth
              sx={{ my: 2 }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
            />

            <TextField
              label="Gender"
              variant="outlined"
              select
              value={gender}
              fullWidth
              onChange={(e) => setGender(e.target.value)}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Adjust based on your UI
                    },
                  },
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "30px",
                },
              }}
              sx={{ my: 2 }}
            >
              {GENDERS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            sx={{ my: 2 }}
          >
            <TextField
              label="Month"
              variant="outlined"
              type="number"
              helperText="Birthday"
              value={birthMonth}
              onChange={(e) => setBirthMonth(e.target.value)}
              inputProps={{ min: 1, max: 12 }}
              sx={{ width: "30%" }}
            />
            <TextField
              label="Day"
              variant="outlined"
              type="number"
              value={birthDay}
              onChange={(e) => setBirthDay(e.target.value)}
              inputProps={{ min: 1, max: 31 }}
              sx={{ width: "30%", mb: 3 }}
            />
            <TextField
              label="Year"
              variant="outlined"
              type="number"
              value={birthYear}
              onChange={(e) => setBirthYear(e.target.value)}
              inputProps={{ min: 1900, max: new Date().getFullYear() }} // Adjust the range as necessary
              sx={{ width: "30%", mb: 3 }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

function PageAppBar({ loading, handleUpdate, name, uploading }) {
  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        style={{ background: "rgba(19,19,19,.9)" }}
      >
        {uploading && <LinearProgress color="secondary" />}
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "67px" }}>
            {/* <CurrentUserDrawer /> */}
            <IconButton onClick={() => (window.location.href = "/")}>
              <Close />
            </IconButton>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleUpdate}
            disabled={loading || name === ""}
            style={{ borderRadius: "20px" }}
          >
            SAVE
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}

export function ProfileImageContainer({ profile, setProfile, setUploading }) {
  const handleUpdateFile = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("img", document.getElementById("profile").files[0]);
    formData.append("user_key", currentUser.key);

    const response = await fetch(`${API_BASE}/user/upload-file`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    setProfile(data.url);
    setUploading(false);
  };

  return (
    <Box
      id="edit-profile"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Avatar src={profile} sx={{ width: 75, height: 75 }} />

      <label htmlFor="profile">
        <Button
          variant="text"
          color="secondary"
          component="span"
          startIcon={<Edit />}
        >
          Edit
        </Button>
      </label>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="profile"
        type="file"
        onChange={() => handleUpdateFile("profile")}
      />
    </Box>
  );
}
