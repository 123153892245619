import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  LinearProgress,
  TextField,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";

import { ArrowBackIos, ArrowUpward } from "@mui/icons-material";

import { API_BASE, authHeaders, currentUser } from "../../App";


export default function Post() {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const handleSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("owner_key", currentUser.key);
    formData.append("artist_key", currentUser.key);
    formData.append("type", "note");
    formData.append("meta", JSON.stringify({ text: text }));
    try {
      setLoading(true);
      const endpoint = API_BASE + "/v1/post";
      const response = await axios.post(endpoint, formData, authHeaders);
      window.location.href = "/" + currentUser.key;
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <PageAppBar handleSubmit={handleSubmit} loading={loading} text={text} />

      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Box style={{ display: "flex", alignItems: "flex-start" }}>
          <Avatar
            src={currentUser.meta.profile}
            sx={{ width: 48, height: 48, mr: 1 }}
            style={{
              border: 1,
              borderColor: "#04FFFF",
              borderStyle: "solid",
              marginTop: "4px",
            }}
          />
          <TextField
            fullWidth
            multiline
            // size="small"
            sx={{ mt: 0 }}
            autoComplete="off"
            placeholder="Share some thoughts..."
            value={text}
            onChange={(e) => setText(e.target.value)}
            margin="normal"
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function PageAppBar({ handleSubmit, loading, text }) {
  const isDisabled = loading || !text;

  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => (window.location.href = "/")}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h6">New Post</Typography>

        <IconButton
          size="small"
          style={{
            background: isDisabled ? "rgba(256,256,256,0.1)" : "#9A5AEF",
          }}
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          <ArrowUpward />
        </IconButton>
      </Toolbar>
      {loading && <LinearProgress />}
    </AppBar>
  );
}
