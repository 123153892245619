import { React } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import Typography from "@mui/material/Typography";

import SimpleAppBar from "../fragments/SimpleAppBar";

import Signatures from "../assets/signatures.svg";

export default function About() {
  const navigate = useNavigate();
  return (
    <Box>
      <SimpleAppBar />

      <Box sx={{ p: 2 }}>
        <Typography variant="h6" style={{ fontWeight: 800 }} sx={{ mb: 2 }}>
          Refund Policy
        </Typography>
        <Typography>
          Notebook does not offer refunds, all purchases are final. You may
          cancel your subscription at any time by visiting
          <Link href="/subscriptions" style={{ color: "white" }}>
            {" "}Subscriptions
          </Link>
          . If you have any questions, please email support@notebook.fm.
        </Typography>
      </Box>
    </Box>
  );
}
