import axios from "axios";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Container from "@mui/material/Container";

import Box from "@mui/material/Box";

import About from "./pages/About";
import Accounts from "./pages/Accounts";
import ArtistData from "./pages/ArtistData";
import ArtistPublish from "./pages/ArtistPublish";
import ArtistSetup from "./pages/ArtistSetup";
import ArtistVault from "./pages/ArtistVault";
import Blocked from "./pages/Blocked";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Media from "./pages/Media";
import Music from "./pages/Music";
import Notes from "./pages/Notes";
import Post from "./pages/Post";
import Privacy from "./pages/Privacy";
import Profile from "./pages/Profile";
import RedeemContainer from "./pages/RedeemContainer";
import Refund from "./pages/Refund";
import Subscribe from "./pages/Subscribe";
import Subscriptions from "./pages/Subscriptions";
import Terms from "./pages/Terms";
import Threads from "./pages/Threads";
import Live from "./pages/Live";

import MusicPlayer from "./components/MusicPlayer";
import PrivateAppBar from "./components/PrivateAppBar";
import ReviewAppBar from "./components/ReviewAppBar";
import ReleaseDate from "./fragments/ReleaseDate";

import { MusicProvider } from "./contexts/MusicContext";

import {
  getApiBase,
  getArtistKey,
  getCurrentUser,
  getHeaders,
  getPageTitle,
  getUIStage,
  setCurrentUser,
} from "./utils";

export const UI_STAGE = getUIStage();
export const API_BASE = getApiBase();

export const artistKey = getArtistKey();
export const currentUser = getCurrentUser();
export const authHeaders = getHeaders();

export default function App() {
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const endpoint = API_BASE + "/user";
        const response = await axios.get(endpoint, {
          params: {
            key: currentUser.key,
          },
        });
        const user = response.data;
        if (user.role === "user" && user.public === false) {
          window.location.href = "/blocked";
        } else {
          setCurrentUser(user);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        window.location.href = "/logout";
      }
    };

    if (currentUser && window.location.pathname !== "/blocked") {
      fetchUser();
    }
  }, [currentUser]);

  return (
    <Container maxWidth="sm" disableGutters>
      <ReviewAppBar />
      <PrivateAppBar />

      {/* {console.log("artistKey", artistKey)} */}
      <Helmet>
        <title>{getPageTitle()}</title>
      </Helmet>
      <MusicProvider>
        <Router>
          <Routes>
            <Route path="/blocked" element={<Blocked />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/artist/setup" element={<ArtistSetup />} />
            <Route path="/logout" element={<Logout />} />

            {!currentUser && (
              <>
                <Route path="/:key" element={<Login />} />
                <Route path="/*" element={<Login />} />
              </>
            )}

            {currentUser && artistKey && (
              <>
                <Route path="/" element={<ArtistVault />} />
                <Route path="/notes" element={<Notes />} />
                <Route path="/media" element={<Media />} />
                <Route path="/music" element={<Music />} />
                <Route path="/threads" element={<Threads />} />
                <Route path="/redeem" element={<RedeemContainer />} />
                <Route path="/live" element={<Live />} />
                <Route path="/:key" element={<ArtistVault />} />
              </>
            )}

            {currentUser && (
              <>
                <Route path="/live" element={<Live />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/subscriptions" element={<Subscriptions />} />
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/:key" element={<ArtistVault />} />
                <Route path="/" element={<Subscriptions />} />
              </>
            )}

            {currentUser?.role == "artist" && (
              <>
                {/* could /artist/prefix this */}
                <Route path="/data" element={<ArtistData />} />
                <Route path="/post" element={<Post />} />
                {/* <Route path="/broadcast" element={<Broadcaster />} /> */}
                <Route path="/publish" element={<ArtistPublish />} />
              </>
            )}
          </Routes>
        </Router>

        <MusicPlayer />
      </MusicProvider>

      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        <ReleaseDate />
      </Box>
    </Container>
  );
}
