import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";



export default function LoadingSkeleton() {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      sx={{ mb: 2 }}
    >
      <Skeleton variant="circular" width={60} height={60} />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginLeft: "10px", // Adjust based on actual layout
        }}
      >
        <Skeleton variant="text" width={100} height={30} />
        <Skeleton variant="text" width={250} height={60} />
        <Skeleton variant="text" width={60} height={30} />
      </Box>
    </Box>
  );
}
