import moment from "moment";
import { useState } from "react";

import { Avatar, Box, Typography } from "@mui/material";

import { Verified } from "@mui/icons-material";

import UserDrawer from "@/components/UserDrawer";

export default function CaptionContainer({ content }) {
  const [showUser, setShowUser] = useState(false);

  const fromNow = (timestamp) => {
    return moment.unix(timestamp).fromNow();
  };
  return (
    <>
      {content.meta.text && (
        <Box
          style={{
            display: "flex",
            // marginBottom: "10px",
          }}
        >
          <Avatar
            src={content.owner.meta.profile}
            sx={{ width: 34, height: 34, marginRight: 1 }}
            onClick={() => setShowUser(true)}
          />

          <Box id="post-content-container" style={{ width: "100%" }}>
            <Box
              id="post-header"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "-5px",
              }}
            >
              <Box
                id="user-detail"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <Typography
                  variant="body"
                  style={{ marginRight: "5px", fontWeight: "bold" }}
                >
                  {content.owner.name}
                </Typography>

                {content.owner.role == "artist" && (
                  <Verified
                    color={content.owner.verified ? "secondary" : "disabled"}
                    style={{ fontSize: "15px", marginRight: "5px" }}
                  />
                )}

                <Typography
                  variant="caption"
                  style={{ marginRight: "5px", color: "lightgray" }}
                  // sx={{mt: 0.5, ml: 0.5}}
                >
                  {/* {console.log(content)} */}
                  {fromNow(content.created)}
                </Typography>
              </Box>
            </Box>

            <Box
              id="post-content"
              style={{ marginTop: "-5px" }}
              // onClick={goToThread}
            >
              {/* needs to wrap long comment */}
              <Box style={{ wordWrap: "break-all", whiteSpace: "pre-wrap" }}>
                <Typography variant="body" style={{ marginRight: "5px" }}>
                  {content.meta.text}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <UserDrawer
        userKey={content.owner.key}
        open={showUser}
        setOpen={setShowUser}
      />
    </>
  );
}
