import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { ArrowBackIos, Verified } from "@mui/icons-material";

import ArtistLogo from "@/fragments/ArtistLogo";

import ArtistAgreement from "./Agreement";

import { API_BASE, currentUser } from "@/App";
import { setCurrentUser } from "@/utils";

export default function ArtistPublish() {
  const [artistAgreement, setArtistAgreement] = useState(false);
  const [loading, setLoading] = useState(false);



  return (
    <Box>
      <PageAppBar />

      <ArtistAgreement />

      {/* {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Ready to go live?
            </Typography>
            <Typography>
              Once you hit publish, your account will enter a review stage.
              You'll get an SMS notification when your review is complete.
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
            }}
            sx={{ my: 4 }}
          >
            <Checkbox
              checked={artistAgreement}
              onChange={(e) => setArtistAgreement(e.target.checked)}
              sx={{ pl: 0, pt: 0.5 }}
            />
            <Typography variant="body1" color="darkgray">
              I have signed the Artist Agreement and am ready to start
              montetizing my account.
            </Typography>
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={!artistAgreement}
            startIcon={<Verified />}
            style={{
              borderRadius: "30px",
            }}
          >
            Publish
          </Button>
        </Box>
      )} */}
    </Box>
  );
}

function PageAppBar() {
  const navigate = useNavigate();
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIos />
        </IconButton>
        <ArtistLogo />
        <Box style={{ width: "40px" }} />
      </Toolbar>
    </AppBar>
  );
}
