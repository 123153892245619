import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import ReactGA from "react-ga4";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactGA.initialize("G-0MVF191XKP");

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
);



// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//       window.location.reload();
//     }
//   },
// });
