import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import AppIcon from "../assets/app-icon.svg";

import { setArtistKey } from "../utils";

export default function ArtistsGallery({ artists }) {
  
  const gotoArtist = (artistKey) => {
    setArtistKey(artistKey);
    window.location.href = "/" + artistKey;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "scroll",
          gap: 1,
          "::-webkit-scrollbar": {
            display: "none",
          },
          my: 2,
        }}
      >
        {artists?.map((artist, index) => (
          <Card
            key={index}
            sx={{
              display: "flex",
              minWidth: 100, // Ensure cards have a minimum width
              mr: 1,
              flexShrink: 0, // Prevent cards from shrinking
            }}
            onClick={() => gotoArtist(artist.key)}
          >
            <CardMedia
              component="img"
              sx={{ width: 60, height: 60 }}
              image={artist.meta.profile || AppIcon}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: 100,
                px: 2,
              }}
            >
              <Typography variant="h7" sx={{fontWeight: 600}}>{artist.name || artist.key}</Typography>
            </Box>
          </Card>
        ))}
      </Box>
    </>
  );
}
