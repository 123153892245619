import axios from "axios";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Drawer,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ArrowUpward, Cancel } from "@mui/icons-material";
import { API_BASE, currentUser } from "@/App";
import CommentCard from "@/components/CommentCard";

function CommentActions({ event, fetchComments, content }) {
  const [open, setOpen] = useState(false);
  const [replying, setReplying] = useState(false);
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleSubmitReply = async () => {
    const data = {
      content_key: event.content_key,
      user_key: currentUser.key,
      type: "comment",
      parent_id: event.id,
      data: {
        text: reply,
      },
    };
    try {
      setLoading(true);
      const endpoint = API_BASE + "/event";
      const response = await axios.post(endpoint, data);
      console.log("Reply:", response.data);
    } catch (error) {
      console.error("Error:", error);
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
      setReplying(false);
      setReply("");
      setOpen(false);
      fetchComments();
    }
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      const endpoint = API_BASE + "/comment/delete";
      const response = await axios.post(endpoint, {
        id: event.id,
        user_key: currentUser.key,
      });
      console.log("Delete:", response.data);
      await fetchComments();
    } catch (error) {
      console.error("Error deleting comment:", error);
    } finally {
      setDeleting(false);
      setDeleteDialogOpen(false);
    }
  };

  const canDelete = () => {
    return (
      event.user_key == currentUser.key || currentUser.key == content.owner.key
    );
  };

  const handleClickReply = () => {
    setOpen(true);
    setReplying(true);
  };

  return (
    <Box>
      <Box style={{ display: "flex", width: "100%" }}>
        <Box
          id="post-actions"
          style={{ display: "flex", justifyContent: "flex-start", gap: 4 }}
        >
          {!event.parent_id && (
            <Button
              variant="text"
              size="small"
              style={{
                color: "lightgray",
                marginLeft: "-10px",
                fontWeight: 400,
              }}
              sx={{ pl: 0 }}
              onClick={handleClickReply}
            >
              Reply
            </Button>
          )}

          {canDelete() && (
            <Button
              variant="text"
              size="small"
              style={{
                color: "lightgray",
                fontWeight: 400,
                marginLeft: "-5px",
              }}
              sx={{ pl: 0 }}
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete
            </Button>
          )}
        </Box>
      </Box>

      <Box>
        {showReplies &&
          event.replies.map((reply, index) => (
            <Box style={{ minHeight: "60px" }} sx={{ my: 2 }} key={index}>
              <CommentCard
                event={reply}
                fetchComments={fetchComments}
                content={content}
              />
            </Box>
          ))}
        {event.replies.length > 0 && (
          <Button
            onClick={() => setShowReplies(!showReplies)}
            variant="text"
            size="small"
            style={{ color: "lightgray" }}
            sx={{ p: 0, textTransform: "none" }}
          >
            <span style={{ opacity: 0.3, marginRight: "5px" }}>⸺</span>
            {showReplies
              ? "Hide replies"
              : `View ${event.replies.length} ${
                  event.replies.length === 1 ? "reply" : "replies"
                }`}
          </Button>
        )}
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Comment</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to remove this comment? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Button onClick={() => setDeleteDialogOpen(false)} color="info">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            autoFocus
            disabled={deleting}
            startIcon={deleting && <CircularProgress size={16} />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <Container maxWidth="sm" sx={{ pb: 10 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ my: 2, pl: 0 }}
              color="inherit"
            >
              <Cancel />
            </IconButton>
          </Box>

          {replying && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "20px 0",
              }}
            >
              <Avatar
                src={currentUser.meta.profile}
                sx={{ width: 40, height: 40 }}
              />
              <TextField
                fullWidth
                multiline
                autoComplete="off"
                maxRows={4}
                size="small"
                placeholder={`Reply to ${event.user.name}...`}
                variant="outlined"
                value={reply}
                disabled={loading}
                sx={{ mx: 1 }}
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
                onChange={(e) => setReply(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmitReply(e);
                  }
                }}
              />
              <Box>
                <IconButton
                  size="small"
                  style={{ background: "#9A5AEF" }}
                  onClick={handleSubmitReply}
                  disabled={loading}
                >
                  <ArrowUpward />
                </IconButton>
              </Box>
            </Box>
          )}
        </Container>
      </Drawer>
    </Box>
  );
}

export default CommentActions;
