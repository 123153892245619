import React from "react";

import Box from "@mui/material/Box";

import SimpleAppBar from "@/fragments/SimpleAppBar";
import TermsAndConditions from "@/fragments/TermsAndConditions";

export default function Terms() {
  return (
    <Box>
      <SimpleAppBar />

      <Box sx={{ p: 2 }}>
        <TermsAndConditions />
      </Box>
    </Box>
  );
}
