import { React } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { Block } from "@mui/icons-material";

export default function Blocked() {
  const navigate = useNavigate();
  return (
    <Box sx={{ pt: 30, px: 2, textAlign: "center" }}>
      <Block sx={{fontSize: "50px", my: 2}} color="info"/>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Your Notebook account is suspended.
      </Typography>
      <Typography variant="body1" color="darkgray" sx={{my: 2}}>
        Any active subscriptions have been cancelled. If you believe this is an error, please contact support@notebook.fm
      </Typography>
      
    </Box>
  );
}
