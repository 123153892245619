import React, { useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ExperienceCard from "../../components/ExperiencesCard";

export default function ExperiencesContainer({ artistContent }) {
  return (
    <Box sx={{ px: 2 }}>
      <Typography variant="h6" sx={{ mt: 4, fontWeight: 800 }}>
        Experiences
      </Typography>
      {artistContent?.length == 0 && (
        <Typography variant="body1" color="darkgray" sx={{ my: 2 }}>
          New experiences coming soon.
        </Typography>
      )}

      {artistContent?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            overflowX: "scroll",
            scrollbarWidth: "none" /* Firefox */,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {artistContent?.map((content, index) => (
            <Box key={index} sx={{ mr: 2 }}>
              <ExperienceCard content={content} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}