import axios from "axios";
import { React, useEffect, useState } from "react";

import { Box } from "@mui/material";

import NoteCard from "./NoteCard";

import BottomTabs from "../../components/BottomTabs";
import MainAppBar from "../../components/MainAppBar";

import CommentSkeleton from "../../fragments/CommentSkeleton";

import { API_BASE, artistKey, currentUser } from "../../App";

export default function Notes() {
  const [loading, setLoading] = useState(true);
  const [artistContent, setArtistContent] = useState(null);

  useEffect(() => {
    const fetchArtistContent = async () => {
      const endpoint = API_BASE + "/notes";
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
          artist_key: artistKey,
        },
      });
      setArtistContent(response.data.content);
      setLoading(false);
    };
    fetchArtistContent();
  }, []);

  return (
    <Box>
      <MainAppBar />

      <Box
        sx={{
          px: 2,
          pb: 15,
        }}
      >
        {loading ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4, mt: 4 }}>
            {/* <CircularProgress /> */}
            <CommentSkeleton />
            <CommentSkeleton />
            <CommentSkeleton />
            <CommentSkeleton />
            <CommentSkeleton />
          </Box>
        ) : (
          <Box id="main-feed">
            <Box style={{ marginBottom: "100px" }}>
              {artistContent?.map((content, index) => (
                <Box key={index}>
                  <NoteCard content={content} />
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {!loading && artistContent.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              mt: 4,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "1.5rem",
                fontWeight: 500,
                color: "text.secondary",
              }}
            >
              No notes found
            </Box>
          </Box>
        )}
      </Box>
      <BottomTabs />
    </Box>
  );
}
