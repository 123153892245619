import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#9A5AEF',
    },
    secondary: {
      main: '#04FFFF',
    },
    info: {
      main: '#828282',
    },
    alt: {
      main: "#02B2AF",
    },
    white: {
      main: '#FFFFFF',
    },
    error: {
      main: red.A400,
    },
    mode: 'dark', // Enable dark mode
  },
  typography: {
    bold: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 800,
    },
  }
});

export default theme;
