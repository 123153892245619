import React, { useState } from "react";
import axios from "axios";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CancelIcon from "@mui/icons-material/Cancel";

import { API_BASE, authHeaders, currentUser, artistKey } from "../../App";
import { Avatar } from "@mui/material";

export default function NewThreadContainer({ open, setOpen, fetchArtistContent }) {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    const payload = {
      owner_key: currentUser.key,
      artist_key: artistKey,
      type: "thread",
      meta: {
        text: text,
        title: title,
      },
    };

    const response = await axios.post(API_BASE + "/topic", payload, authHeaders);
    fetchArtistContent();
    setLoading(false);
    setText("");
    setTitle("");
    setOpen(false);
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        style={{ height: "100%" }}
        PaperProps={{ style: { borderRadius: "20px 20px 0 0" } }}
      >
        <Box
          elevation={20}
          style={{
            borderRadius: "0",
            padding: "20px",

            height: "50vh",
            overflowY: "scroll",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <IconButton
              variant="text"
              color="info"
              onClick={() => setOpen(false)}
              
            >
              <CancelIcon />
            </IconButton>
            <Typography variant="h6">New Topic</Typography>

            <Box style={{ width: "50px" }} />
          </Box>

          <TextField
            fullWidth
            autoComplete="off"
            size="small"
            placeholder="Topic Title..."
            variant="standard"
            value={title}
            sx={{ my: 2 }}
            InputProps={{
              style: {
                borderRadius: "20px",
              },
            }}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              margin: "25px 0",
              alignItems: "flex-start",
            }}
          >
            <Avatar
              src={currentUser.meta.profile}
              sx={{ width: 30, height: 30, mr: 2, mt: 0.5}}
            />
            <TextField
              fullWidth
              multiline
              autoComplete="off"
              size="small"
              maxRows={4}
              placeholder="Start the conversation with a first comment..."
              variant="outlined"
              value={text}
              style={{ marginRight: "20px" }}
              // make the textfield rounded
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => {
                if (e.ctrlKey && e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
            />

            <IconButton
              size="small"
              style={{ background: "#9A5AEF" }}
              onClick={handleSubmit}
              sx={{mt: 0.5}}
              disabled={loading || title.length == 0 || text.length == 0}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Box>
        </Box>
      </Drawer>
      
    </>
  );
}
